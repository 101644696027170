import React from 'react';

import InfoCard from '_app/components/InfoCard';
import {RadiologyAvatar} from '_app/art/MedicalIcons';

export default function RadiologyCard(props) {
    return (
        <InfoCard color='blue' Avatar={RadiologyAvatar} title="Radiology" {...props}>
        </InfoCard>
    )
}
