import React from 'react';
import * as R from 'react-router-dom';

import routes, {history} from '../routes';
import {ApiRoute} from '@arborian/narrf';

import HomePage from '_app/pages/HomePage';
import PasswordResetPage from '_app/pages/PasswordResetPage';
import LoginPage from '_app/pages/LoginPage';
import CallbackPage from '_app/pages/CallbackPage';
import LogoutPage from '_app/pages/LogoutPage';
import ProfilePage from '_app/pages/ProfilePage';
import RegisterPage from '_app/pages/RegisterPage';
import InvitationPage from '_app/pages/InvitationPage';
import AboutPage from '_app/pages/AboutPage';
import MarkdownPage from '_app/pages/MarkdownPage';

import PatientSelectPage from '_app/pages/PatientSelectPage';
import PatientHomePage from '_app/pages/PatientHomePage';
import HistoryPage from '_app/pages/HistoryPage';
import InfoPage from '_app/pages/InfoPage';

import AdminUsersPage from '_app/pages/AdminUsersPage';
import AdminOrgPage from '_app/pages/AdminOrgPage';
import AdminPatientsPage from '_app/pages/AdminPatientsPage';
import AdminMessagePage from '_app/pages/AdminMessagePage';

import termsOfService from '_app/data/termsOfService';
import privacyPolicy from '_app/data/privacyPolicy';

const PrivacyPolicy = props => (
    <MarkdownPage title='Privacy Policy' markdown={privacyPolicy} {...props} />
);
const TOS = props => (
    <MarkdownPage
        title='Terms of Service'
        markdown={termsOfService}
        {...props}
    />
);

export default function Router() {
    return (
        <R.Router history={history}>
            <R.Switch>
                <ApiRoute
                    exact
                    path={routes.privacy.path}
                    component={PrivacyPolicy}
                />
                <ApiRoute exact path={routes.terms.path} component={TOS} />
                <ApiRoute exact path={routes.home.path} component={HomePage} />
                <ApiRoute
                    exact
                    path={routes.login.path}
                    component={LoginPage}
                />
                <ApiRoute
                    exact
                    path={routes.passwordReset.path}
                    component={PasswordResetPage}
                />
                <ApiRoute
                    exact
                    path={routes.logout.path}
                    component={LogoutPage}
                />
                <ApiRoute
                    exact
                    path={routes.register.path}
                    component={RegisterPage}
                />
                <ApiRoute
                    exact
                    path={routes.invitation.path}
                    component={InvitationPage}
                />
                <ApiRoute
                    exact
                    path={routes.about.path}
                    component={AboutPage}
                />
                <ApiRoute
                    exact
                    path={routes.callback.path}
                    component={CallbackPage}
                />

                <ApiRoute
                    authorized
                    exact
                    path={routes.profile.path}
                    component={ProfilePage}
                />

                <ApiRoute
                    authorized
                    exact
                    path={routes.patientSelect.path}
                    component={PatientSelectPage}
                />
                <ApiRoute
                    authorized
                    exact
                    path={routes.patientHome.path}
                    component={PatientHomePage}
                />
                <ApiRoute
                    authorized
                    exact
                    path={routes.history.path}
                    component={HistoryPage}
                />
                <ApiRoute
                    authorized
                    exact
                    path={routes.info.path}
                    component={InfoPage}
                />

                <ApiRoute
                    authorized
                    exact
                    path={routes.adminOrg.path}
                    component={AdminOrgPage}
                />
                <ApiRoute
                    authorized
                    exact
                    path={routes.adminPatients.path}
                    component={AdminPatientsPage}
                />
                <ApiRoute
                    authorized
                    exact
                    path={routes.adminUsers.path}
                    component={AdminUsersPage}
                />
                <ApiRoute
                    authorized
                    exact
                    path={routes.adminMessage.path}
                    component={AdminMessagePage}
                />
            </R.Switch>
        </R.Router>
    );
}
