import React from 'react';
import clsx from 'clsx';

import {Hidden, makeStyles} from '@material-ui/core';

import Walker from '_app/art/Walker';

const useStyles = makeStyles(theme => ({
    banner: {
        position: 'absolute',
        backgroundColor: theme.palette.cf.blue + '0c', 
        height: theme.spacing(30),
        left: 0,
        right: 0,
        borderRadius: 5,
        overflow: 'hidden',
    },
    children: {
        marginRight: 375,
        padding: theme.spacing(1),
    },
    walker: {
        position: 'absolute',
        right: theme.spacing(4),
        top: theme.spacing(4),
    },
}));

export default function Banner({children, classes={}}) {
    const d = useStyles();
    return (
        <Hidden xsDown>
            <div className={clsx(d.banner, classes.banner)}>
                {children && <div className={clsx(d.children, classes.children)}>
                    {children}
                </div>}
                <div className={clsx(d.walker, classes.walker)}>
                    <Walker/>
                </div>
            </div>
        </Hidden>
    );
}
