import React from 'react';
import * as R from 'react-router-dom';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {
    Avatar, Button, Link,
    makeStyles, 
} from '@material-ui/core';
import * as I from '@material-ui/icons';

import {url_for} from '_app/routes';
import {useApi, ducks} from '@arborian/narrf';


const useStyles = makeStyles(theme => ({
    authInfo: {
        display: "flex",
        alignItems: "center"
    },
}));

export default function AuthInfo() {
    const classes = useStyles();

    const api = useApi();
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    let userLabel = fp.get('name', userinfo);
    if(!userLabel) {
        userLabel = fp.get('email', userinfo);
    }

    if(!api.isAuthorized()) {
        return (
            <>
                <Button color="inherit" component={R.Link} to={url_for('login')}>
                    Sign In
                </Button>
                <Link component={R.Link} to={url_for('register')}>Register</Link>
            </>
        )
    }
    return (
        <div className={classes.authInfo}>
            <Avatar icon={<I.AccountCircle />}/>
            &nbsp;
            <Link component={R.Link} to={url_for('profile')}>{userLabel}</Link>
            <Button color="inherit" component={R.Link} to={url_for('logout')}>
                Sign out
            </Button>
        </div>
    );
}

