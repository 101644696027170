import React from 'react';

import InfoCard from '_app/components/InfoCard';
import {SyringeAvatar} from '_app/art/MedicalIcons';

export default function ImmunizationsCard(props) {
    return (
        <InfoCard color='blue' Avatar={SyringeAvatar} title="Immunizations" {...props}>
        </InfoCard>
    )
}
