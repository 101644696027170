import React from 'react';

import {Paper, makeStyles} from '@material-ui/core';

import NavPage from '_app/components/NavPage';
import ProfileForm from '_app/components/ProfileForm';

const useStyles = makeStyles(theme => ({
    paper: {
        width: '100%',
        margin: theme.spacing(1),
        padding: theme.spacing(2),
    },
    formBox: {
        maxWidth: theme.spacing(80),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.cf.accent,
        border: '1px solid #D1D3D4',
        borderRadius: 10,
        boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.25)',
    }
}));



export default function ProfilePage() {
    const classes = useStyles()
    return (
        <NavPage title="User Profile">
            <Paper className={classes.paper}>
                <div className={classes.formBox}>
                    <ProfileForm />
                </div>
            </Paper>
        </NavPage>
    )
}
