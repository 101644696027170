import React, {useRef} from 'react';
import fp from 'lodash/fp';

import MaterialTable from 'material-table';

import {useApi} from '@arborian/narrf';


export default function AuthorizedUserTable({patient}) {
    const api = useApi();
    const tableRef = useRef();

    const data = async o => {
        const resp = await api.fetchMaterialTable(patient.relationships.group_members.links.related, o)
        console.log('Got resp', resp);
        return resp
    }

    const onRowDelete = async row => {
        let resp = await api.fetch(patient.relationships.group_members.links.self, {
            method: 'DELETE',
            json: {data: [{type: 'Sub', id: fp.get('data.id', row)}]}
        });
        console.log('Got resp', resp);
    }
    const refresh = () => tableRef.current.onQueryChange();

    return (
        <MaterialTable
            title="Authorized users"
            data={data}
            editable={{onRowDelete}}
            options={{
                filtering: false,
                search:false,
            }}
            actions={[
                {icon: 'refresh', isFreeAction: true, tooltip: 'Refresh', onClick: refresh}
            ]}
            columns={[
                {title: 'Name', field: 'data.attributes.userinfo.name'},
                {title: 'Email', field: 'data.attributes.userinfo.email'},
            ]}
        />
    )
}
