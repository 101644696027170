import {useState, useCallback, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const debounce = f => {
    let cur = null;
    return async (...args) => {
        if (cur) {
            const result = await cur;
            return result;
        } else {
            cur = f(...args);
            try {
                return await cur;
            } finally {
                cur = null;
            }
        }
    };
};

export const useQuery = () => new URLSearchParams(useLocation().search);

export const useAsyncEffect = (f, deps) => {
    const [busy, setBusy] = useState(false);

    useEffect(
        () => {
            if (!busy) {
                setBusy(true);
                f().then(() => setBusy(false));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deps,
    );
};

export const useAsyncMemo = (f, deps = [], defaultValue = undefined) => {
    const [value, setValue] = useState(defaultValue);
    useEffect(
        () => {
            f().then(setValue);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deps,
    );
    return value;
};

export const useStorage = (storage, key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const svalue = storage.getItem(key);
            return svalue ? JSON.parse(svalue) : initialValue;
        } catch (error) {
            console.error('Error loading storage', error);
            return initialValue;
        }
    });

    const setValue = useCallback(
        value => {
            try {
                const valueToStore =
                    value instanceof Function ? value(storedValue) : value;
                setStoredValue(valueToStore);
                storage.setItem(key, JSON.stringify(valueToStore));
            } catch (error) {
                console.error('Error saving storage', error);
            }
        },
        [storage, key, storedValue, setStoredValue],
    );
    return [storedValue, setValue];
};

// PKCE stuff
export const generateRandomString = bytes => {
    var array = new Uint8Array(bytes);
    window.crypto.getRandomValues(array);
    return btoa(array);
};

export const pkceChallengeFromVerifier = async v => {
    const encoder = new TextEncoder();
    const data = encoder.encode(v);
    const digest = await window.crypto.subtle.digest('SHA-256', data);
    const bytes = new Uint8Array(digest);
    const string = String.fromCharCode.apply(null, bytes);
    const b64_challenge = btoa(string);
    const urlsafe_challenge = b64_challenge
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
    return urlsafe_challenge;
};
