import React from 'react';

import InfoCard from '_app/components/InfoCard';
import {PillAvatar} from '_app/art/MedicalIcons';

export default function BloodSugarCard(props) {
    return (
        <InfoCard color='blue' Avatar={PillAvatar} title="Medications" {...props}>
        </InfoCard>
    )
}
