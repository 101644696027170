import React from 'react';

import VitalCard from '_app/components/VitalCard';
import {WindAvatar} from '_app/art/MedicalIcons';

export default function RespirationCard({patient, pcc}) {
    return (
        <VitalCard color='blue' Avatar={WindAvatar} type="respirations" patient={patient} {...pcc}>
            <h6>Respiration rate</h6>
        </VitalCard>
    )
}
