import React, {useMemo}from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';
import MaterialTable from 'material-table';
import UriTemplate from 'uritemplate';
import downloadjs from 'downloadjs';

import {useApi, ducks} from '@arborian/narrf';

import {DateTime} from '_app/components/DateTime';

const columns = [
    // {title: 'Test', render: row => <pre>{JSON.stringify(row.attributes, null, 2)}</pre>},
    {title: 'Name', field: 'data.attributes.pcc.reportName'},
    {title: 'Status', field: 'data.attributes.pcc.reportStatus'},
    {
        title: 'Effective', field: 'data.attributes.pcc.effectiveDateTime',
        render: row => <DateTime value={fp.get('data.attributes.pcc.effectiveDateTime', row)}/>,
        type: 'datetime', filtering: false,
    },
    {
        title: 'Issued', field: 'data.attributes.pcc.issuedDateTime',
        render: row => <DateTime value={fp.get('data.attributes.pcc.issuedDateTime', row)}/>,
        type: 'datetime', filtering: false,
    },
];

function renderValue(vq) {
    let parts = [
        fp.get('value', vq),
        fp.get('unitText', vq),
    ]
    return fp.filter(r => r, parts).join(' ')
}

function Test({test}) {
    const tableData = useMemo(
        () => fp.map(result => ({data: result}), test.results),
        [test]
    );
    return (
        <div>
            <MaterialTable title={test.panelName}
                options={{
                    search: false, paging: false, filter: false,
                    pageSize: fp.size(test.results),
                    toolbar: !fp.isEmpty(test.panelName)
                }}
                columns={[
                    {title: 'Observation', field: 'data.observationName'},
                    {title: 'Value', render: fp.flow(fp.get('data.valueQuantity'), renderValue)},
                    {title: 'Reference', field: 'data.referenceRange'},
                    {title: 'Comment', field: 'data.comment'},
                ]}
                data={tableData}
            />
        </div>
    )
}

function Details({rpt}) {
    return (
        <>
            {fp.flow(
                fp.get('data.attributes.pcc.testSet'),
                fp.entries,
                fp.map(([i, test]) => <Test key={i} test={test}/>)
            )(rpt)}
        </>
    )
}

export default function ReportTable({patient, reportType, ...props}) {
    const api = useApi();
    const blobs = useSelector(ducks.jsonapi.selectObject('Blob'));

    if(!patient) return null;
    const fetch = async o => {
        const url = UriTemplate.parse(
            api.directory.data.links['artifact.artifacts']
        ).expand({patientId: patient.id, atype: 'diagnosticReport'});
        let resp = await api.fetchMaterialTable(url, {
            ...o,
            filter: {'pcc.reportType': reportType},
            include: ['file'],
        });
        return resp;
    }

    const downloadFile = (ev, row) => {
        const blob = blobs[row.data.relationships.file.data.id];
        downloadjs(blob.links.content);
    }


    return (
        <MaterialTable 
            data={fetch}
            columns={columns}
            options={{
                filtering: true
            }}
            detailPanel={rpt => <Details rpt={rpt}/>}
            actions={[
                rpt => ({
                    icon: 'cloud_download',
                    tooltip: 'Download file',
                    disabled: !fp.get('data.attributes.pcc.reportFile', rpt),
                    onClick: downloadFile
                })
            ]}
            {...props}
        />
    );
}
