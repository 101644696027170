import React, {useEffect, useCallback, useState} from 'react';
import fp from 'lodash/fp';
import {useLocation} from 'react-router-dom';

import {makeStyles} from '@material-ui/core';

import BasePage from '_app/components/BasePage';
import Logo from '_app/components/Logo';
import Footer from '_app/components/Footer';
import Banner from '_app/components/Banner';

import {useApi} from '@arborian/narrf';

import {url_for} from '_app/routes'

import PasswordLoginForm from '_app/components/PasswordLoginForm';

import PCCLoginForm from '_app/components/PCCLoginForm';

const useStyles = makeStyles(theme => ({
    logoContainer: {
        margin: theme.spacing(2),
    },
    content: {
        flex: 1,
        margin: theme.spacing(2),
    },
    /*
    container: {
        position: 'relative',
    },
    */
    loginContainer: {
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(4),
        },
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-end',
        justifyContent: 'space-evenly',
        width: "100%",
    },
    banner: {
        zIndex: -1
    },
}));

export default function HomePage() {
    const api = useApi();
    const classes = useStyles();
    const {state} = useLocation();
    const [init, setInit] = useState(false);
    const [providers, setProviders] = useState({});

    const fetchProviders = useCallback(async () => {
        const redirect_uri = new URL(url_for('callback'), window.location.href);
        const authorizeLink = api.authorizeLink({redirect_uri, intent: 'login', state});
        try {
            let rv = await fetch(authorizeLink);
            if(rv.status === 300) {
                let data = await rv.json();
                console.log('Got data', data);
                const providers = fp.pipe([
                    fp.get('options'),
                    fp.toPairs,
                    fp.map(
                        ([id, attributes]) => ({id, attributes})
                    ),
                    fp.map(p => ([p.attributes.plugin_id, p])),
                    fp.fromPairs
                ])(data);
                console.log('got providers', providers);
                setProviders(providers);
            } else {
                console.log('Unknown response getting providers', rv);
            }
        } catch(e) {
            console.log('Got error fetching providers')
        }
    }, [api, state, setProviders]);

    useEffect(() => {
        if(!init) {
            setInit(true);
            fetchProviders();
        }
    }, [fetchProviders, init, setInit]);
    const next = url_for('adminPatients');

    return (
        <BasePage>
            <div className={classes.logoContainer}><Logo /></div>
            <div className={classes.content}>
                <Banner classes={classes}/>
                <div className={classes.loginContainer}>
                    <PasswordLoginForm classes={classes} title="Resident and Family Login" next={url_for('patientSelect')}/>
                    <PCCLoginForm state={{next}} provider={fp.get('pcc', providers)} title="Healthcare Professionals"/>
                </div>
            </div>
            <Footer wide/>
        </BasePage>
    )
}
