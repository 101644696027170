import React from 'react';
import {Provider as ReduxProvider} from 'react-redux';
import {createBrowserHistory} from 'history';
import {MuiThemeProvider, CssBaseline} from '@material-ui/core';
import {configureStore} from '@reduxjs/toolkit';
import {SnackbarProvider} from 'notistack';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsAdapter from '@date-io/date-fns';
import 'typeface-montserrat';

import {reducers as narrfReducers, ApiProvider} from '@arborian/narrf';
import CloseSnackbarButton from '_app/components/CloseSnackbarButton';
import Router from '_app/components/Router';
import theme from '_app/theme';
import {reducers as cfReducers} from '_app/duck';

const API_ROOT = process.env.REACT_APP_API_ROOT;
const APP_KEY = process.env.REACT_APP_APP_KEY;
const store = configureStore({
    reducer: {
        ...cfReducers,
        ...narrfReducers,
    },
});

const history = createBrowserHistory();

export default function App() {
    return (
        <ReduxProvider store={store}>
            <ApiProvider
                ui_home='/'
                ui_login='/'
                history={history}
                api_root={API_ROOT}
                client_id={APP_KEY}
            >
                <CssBaseline />
                <MuiThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider utils={DateFnsAdapter}>
                        <SnackbarProvider
                            action={key => (
                                <CloseSnackbarButton notiKey={key} />
                            )}
                        >
                            <Router />
                        </SnackbarProvider>
                    </MuiPickersUtilsProvider>
                </MuiThemeProvider>
            </ApiProvider>
        </ReduxProvider>
    );
}
