import React from 'react';

import {ButtonBase, makeStyles} from '@material-ui/core';

import {useApi} from '@arborian/narrf';

const useStyles = makeStyles(theme => ({
    root: {
        width: 244,
        height: 50,
        // backgroundSize: 'contain',
        backgroundImage: 'url(/img/pcc/signin-button-default-base.svg)',
        '&:hover': {
            backgroundImage: 'url(/img/pcc/signin-button-default-hover.svg)',
        },
        '&:active': {
            backgroundImage: 'url(/img/pcc/signin-button-default-pressed.svg)',
        }
    },
    disabled: {
        backgroundImage: 'url(/img/pcc/signin-button-default-disabled.svg)',
    }
}));

export default function PccLoginButton({provider, state={}, intent='login', ...props}) {
    const api = useApi();
    const classes = useStyles();

    if(!provider) return null;
    const redirect_uri = new URL('/callback', window.location.href);
    const authorizeLink = api.authorizeLink({
        redirect_uri, provider_id: provider.id, 
        intent, state
    });
    return <ButtonBase disableRipple classes={classes} href={authorizeLink} {...props}/>
}
