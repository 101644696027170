import React from 'react';
import {useDispatch} from 'react-redux';

import fp from 'lodash/fp';
import {useHistory} from 'react-router-dom';

import {MoreVert} from '@material-ui/icons';
import {
    Card, CardHeader, CardActions, 
    IconButton, Button,
    makeStyles
} from '@material-ui/core';

import {ducks} from '@arborian/narrf';

import {url_for} from '_app/routes';
import {humanName, humanAge} from '_app/util';
import PatientAvatar from '_app/components/PatientAvatar';

const useStyles = makeStyles(theme => ({
    card: {
        minWidth: theme.spacing(32),
        margin: theme.spacing(2, 0),
        backgroundColor: theme.palette.cf.blue + '0c', 
        [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(2),
        },
    }
}));


export default function PatientCard({patient}) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const name = humanName(patient.attributes.pcc);
    const age = humanAge(patient.attributes.pcc);

    const onClick = () => {
        dispatch(ducks.jsonapi.clearData());
        history.push(url_for('patientHome', {patientId: patient.id}))
    }

    return (
        <Card className={classes.card}>
            <CardHeader 
                avatar={<PatientAvatar patient={patient}/>}
                // title={<strong>{name}</strong>}
                title={name}
                subheader={(<span>
                    Age: <strong>{age}</strong>&nbsp;
                    Gender: <strong>{fp.get('attributes.pcc.gender', patient)}</strong>
                </span>)}
                action={
                    <IconButton aria-label="settings">
                        <MoreVert/>
                    </IconButton>
                }
            />
            <CardActions>
                <Button fullWidth color="primary" variant="contained" onClick={onClick}>
                    View Details
                </Button>
            </CardActions>
        </Card>
    );
}
