import {createAction, createSelector} from '@reduxjs/toolkit';
import fp from 'lodash/fp';

export const NAME = 'narrf.jsonapi';

export const DATA = NAME + '/DATA';
export const DELETE = NAME + '/DELETE';
export const DIRECTORY = NAME + '/DIRECTORY';
export const CLEAR = NAME + '/CLEAR';

// action creators
export const directory = createAction(DIRECTORY);
export const receiveData = createAction(DATA);
export const deleteData = createAction(DELETE);
export const clearData = createAction(CLEAR);

// selectors
export const selectObjects = fp.get(`${NAME}.objects`);
export const selectDirectory = state => {
    const key = `${NAME}.directory`;
    const value = fp.get(key, state);
    return value;
};

// export const selectDirectory = fp.get(`${NAME}.directory`);

export const selectObject = path => createSelector(selectObjects, fp.get(path));

export const selectRelated = obj => state => {
    const result = {};
    const rel_pairs = fp.toPairs(fp.get('relationships', obj));
    const rel_one = fp.filter(
        ([rtype, rbody]) => fp.isObject(fp.get('data', rbody)),
        rel_pairs,
    );
    const rel_many = fp.filter(
        ([rtype, rbody]) => fp.isArray(fp.get('data', rbody)),
        rel_pairs,
    );
    fp.forEach(([rtype, rbody]) => {
        const related = selectObject([rbody.data.type, rbody.data.id])(state);
        result[rtype] = related;
    }, rel_one);
    fp.forEach(([rtype, rbody]) => {
        const related = fp.map(
            item => selectObject([item.type, item.id])(state),
            rbody.data,
        );
        result[rtype] = related;
    }, rel_many);
    return result;
};

const initialState = {
    directory: null,
    objects: {},
};

const reduceObject = (state, data) =>
    fp.set(['objects', data.type, data.id], data, state);

export default function jsonApiReducer(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case DIRECTORY: {
            return fp.set('directory', payload, state);
        }
        case DELETE: {
            const {type, id} = payload;
            return {
                ...state,
                objects: {
                    ...state.objects,
                    [type]: fp.omit(id, state.objects[type]),
                },
            };
            // return fp.omit(`objects.${payload.type}.${payload.id}`, state);
        }
        case DATA: {
            // Single object
            if (payload.data && payload.data.type) {
                state = reduceObject(state, payload.data);
            }
            // Included objects
            if (payload.included && payload.included.length) {
                state = fp.reduce(reduceObject, state, payload.included);
            }
            // Collection response
            if (payload.data && payload.data.length) {
                state = fp.reduce(reduceObject, state, payload.data);
            }
            return state;
        }
        case CLEAR: {
            return fp.set('objects', {}, state);
        }
        default:
            return state;
    }
}
