import React from 'react';
import fp from 'lodash/fp'
import {useSelector} from 'react-redux';

import {Divider, makeStyles} from '@material-ui/core';

import {ducks} from '@arborian/narrf';

import AdminPage from '_app/components/AdminPage';
import AdminPatientTable from '_app/components/AdminPatientTable';

const useStyles = makeStyles(theme => ({
    section: {
        '& header': {
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        '& header hr': {
            margin: theme.spacing(2),
            flex: 1
        },
    },
}));

export default function AdminPatientsPage() {
    const classes = useStyles();
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    return (
        <AdminPage title="Admin Patients">
            <section className={classes.section}>
                <header>
                    <h5>Welcome, {fp.get('name', userinfo)}!</h5>
                    <Divider/>
                </header>
                <AdminPatientTable />
            </section>
        </AdminPage>
    )
}

