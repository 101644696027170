import React from 'react';
import * as R from 'react-router-dom';
import {url_for} from '_app/routes';

import {
    Tooltip, Button, 
    Card, CardHeader, CardContent, CardActions, 
    makeStyles
} from '@material-ui/core';

import {Date} from '_app/components/DateTime';

const useStyles = makeStyles(theme => ({
    cardHeader: {
        color: props => theme.palette.cf[props.color],
    },
    cardContent: {
        alignItems: 'center',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
}));

export default function VitalCard({
    title, subheader, color, children, Avatar, value, unit,
    recordedBy, recordedDate, patient,
    type='undefined'
}) {
    const classes = useStyles({color});
    if(!title) {
        title = `${value} ${unit}`
    }
    if(!subheader) {
        subheader = (
            <Tooltip title={`recorded by ${recordedBy}`}>
                <Date value={recordedDate}/>
            </Tooltip>
        );
    }
    if(!Avatar) {
        console.log('Bad avatar', title);
        return null;
    }
    const searchParams = new URLSearchParams()
    searchParams.set('endDate', recordedDate);
    const url = url_for('history', {patientId: patient.id, type}) + '?' +  searchParams;
    return (
        <Card>
            <CardHeader 
                avatar={<Avatar color={color}/>}
                disableTypography
                title={<h6>{title}</h6>}
                subheader={subheader}
                className={classes.cardHeader}
            />
            <CardContent className={classes.cardContent}>
                {children}
            </CardContent>
            <CardActions>
                <Button fullWidth variant="contained" color="primary"
                    component={R.Link} to={url}
                >View history</Button>
            </CardActions>
        </Card>
    );
}
