import React, {useEffect, useMemo} from 'react';
import {createSelector} from '@reduxjs/toolkit';
import {useDispatch, useSelector} from 'react-redux';
import fp from 'lodash/fp';
import * as yup from 'yup';
import UriTemplate from 'uritemplate';
import {Formik, Form, Field} from 'formik'

import {Button, makeStyles} from '@material-ui/core';
import {TextField} from 'formik-material-ui';

import {useApi, ducks} from '@arborian/narrf';

const useStyles = makeStyles(theme => ({
    textField: {
        margin: theme.spacing(1, 0),
        '& .MuiInputBase-root': {
            backgroundColor: 'white'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000',
        },
    },
}));


const SignupSchema = yup.object().shape({
    name: yup.string().required(),
    password: yup.string().min(6),
    password2: yup.string().oneOf([yup.ref('password'), null], "Passwords don't match"),
});

const selectPasswordIdentity = createSelector(
    ducks.auth.selectUserinfo,
    ducks.jsonapi.selectObject('Sub'),
    ducks.jsonapi.selectObject('Identity'),
    (userinfo, subs, idents) => {
        const ident = fp.pipe([
            fp.get(userinfo.sub),
            fp.get('relationships.identities.data'),
            fp.map(link => fp.get(link.id, idents)),
            fp.filter(ident => 'password' === fp.get('attributes.plugin_id', ident)),
            fp.first
        ])(subs);
        return ident;
    }
)



export default function ProfileForm() {
    const api = useApi();
    const dispatch = useDispatch();
    const classes = useStyles();
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    const passwordIdent = useSelector(selectPasswordIdentity);

    const onSubmit = async (attributes, formikProps) => {
        const url = UriTemplate.parse(
            api.directory.data.links['auth.profile']
        ).expand({subId: userinfo.sub});
        await api.fetchJson(url, {
            method: 'PATCH',
            json: {data: {
                type: 'Profile',
                id: userinfo.sub,
                attributes
            }}
        });
        formikProps.resetForm();
        const data = await api.fetchJson(api.directory.data.links['oauth.userinfo'])
        dispatch(ducks.auth.userinfo(data));
    }
    const initialValues = useMemo(() => ({
        name: userinfo.name,
        password: '',
        password2: ''
    }), [userinfo]);

    useEffect(() => {
        api.fetchJsonApi(userinfo.sub_profile, {
            include: ['identities']
        });
    }, [api, userinfo]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={SignupSchema}
            enableReinitialize
            onSubmit={onSubmit}
        >{formikProps => (
            <Form onSubmit={formikProps.handleSubmit}>
                <h6>User Profile</h6>
                <Field fullWidth variant='outlined' component={TextField} className={classes.textField}
                    name="name" label="Your name"/>
                {passwordIdent && (
                    <>
                        <Field fullWidth variant='outlined' component={TextField} className={classes.textField}
                            type="password" name="password" label="New password"/>
                        <Field fullWidth variant='outlined' component={TextField} className={classes.textField}
                            type="password" name="password2" label="New password (again)"/>
                    </>
                )}
                <Button type="submit" fullWidth variant="contained" color="primary"
                    onClick={formikProps.handleSubmit}
                >
                    Update Profile
                </Button>
            </Form>
        )}</Formik>
    );
}
