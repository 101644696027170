import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import {useApi} from '@arborian/narrf';
import {url_for} from '_app/routes';

export default function AutoLogout() {
    const timeout = 1000 * 60 * 15; // 15 mins
    const api = useApi();
    const history = useHistory();
    const onIdle = useCallback(() => {
        if(api.isAuthorized()) {
            history.push(url_for('logout'));
        }
    }, [api, history])
    return <IdleTimer timeout={timeout} onIdle={onIdle}/>
}
