import React from 'react';
import {useParams} from 'react-router-dom';

import {makeStyles} from '@material-ui/core';

import BasePage from '_app/components/BasePage';
import Logo from '_app/components/Logo';
import Footer from '_app/components/Footer';

import PasswordResetForm from '_app/components/PasswordResetForm';


const useStyles = makeStyles(theme => ({
    logoContainer: {
        margin: theme.spacing(2),
    },
    content: {
        flex: 1,
        margin: theme.spacing(2),
    },
}));


export default function PasswordResetPage() {
    const classes = useStyles();
    const {tok} = useParams();

    return (
        <BasePage>
            <div className={classes.logoContainer}><Logo /></div>
            <div className={classes.content}>
                <PasswordResetForm title="Reset your password" tok={tok}/>
            </div>
            <Footer wide/>
        </BasePage>
    )
}
