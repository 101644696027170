import React from 'react';
import clsx from 'clsx';

import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    icon: {
        width: 24,
        height: 24,
        enableBackground: 'new 0 0 150 130',
    },
    path: {
        fill: theme.palette.cf.blue,
        // fill: '#1148F3'
    },
}));


export default function CFIcon({classes={}}) {
    const d = useStyles();
    return (
        <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 150 130" className={clsx(d.icon, classes.icon)} space="preserve">
            <g id="XMLID_499_">
                <path id="XMLID_500_" className={clsx(d.path, classes.path)} d="M119.5,13.6c0.7,0.7,1.9,0.7,2.6-0.1c1.1-1.2,2.8-2.8,4.2-3.4c1.7-0.7,3.5-0.9,5.3-0.8
                    c2.1,0.1,4,0.6,5.8,1.5c2.9,1.5,4.6,3.7,5.3,6.6c0.6,2.7-0.1,5.3-1.3,7.8c-0.7,1.4-1.5,2.7-2.5,3.9c-2.4,3.2-5.4,5.9-8.5,8.5
                    c-2,1.7-5.9,4.2-8.1,5.7c-1,0.7-2.4,0.6-3.4-0.1c-1.8-1.4-4.8-3.5-6.5-4.9c-3.3-2.5-6.4-5.3-9-8.4c-1.6-1.9-3-3.9-3.9-6.2
                    c-0.7-1.8-0.9-3.7-0.6-5.6c0.3-2.1,1.3-4,3-5.7c1.6-1.6,3.7-2.5,6.1-2.9c2.1-0.3,4.2-0.3,6.2,0.3C115.9,10.4,118.1,12.3,119.5,13.6
                    z"/>
            </g>
            <g id="XMLID_626_">
                <path id="XMLID_627_" className={clsx(d.path, classes.path)} d="M83.6,54.6l-1.1,0.7c-4,2.4-9.1,2.4-12.8-0.4c-3.7-2.8-8.3-4.4-13.7-4.4
                    c-11.4,0-19.3,9.4-19.3,23.2c0,14.2,7.8,23.4,19.3,23.4c5.4,0,10-1.4,13.6-4.3c3.9-3.1,9.2-3.7,13.5-1.2l1,0.6
                    c7.3,4.3,7.8,14.7,0.9,19.7c-7.9,5.7-18.3,8.8-30.6,8.8c-28.3,0-47.4-18.8-47.4-46.8c0-28.3,19.3-47.3,47.8-47.3
                    c11.4,0,21.5,2.9,29.3,8.4C91.2,39.8,90.9,50.2,83.6,54.6z"/>
            </g>
        </svg>
    )
}
