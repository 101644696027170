import React from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';

import {ducks} from '@arborian/narrf';

import {formatDate, formatDateTime} from '_app/util';

const useTimeZone = () => {
    const {patientId} = useParams();
    const patient = useSelector(ducks.jsonapi.selectObject(['Patient', patientId]));
    const rel = useSelector(ducks.jsonapi.selectRelated(patient));
    return fp.get('attributes.pcc.timeZone', rel.facility);
}
    

export function Date({value}) {
    const timeZone = useTimeZone();
    return <>{formatDate(value, timeZone)}</>
}

export function DateTime({value}) {
    const timeZone = useTimeZone();
    return <>{formatDateTime(value, timeZone)}</>
}

