import React, {useEffect, useState, useRef} from 'react';

import MaterialTable from 'material-table';

import {useApi} from '@arborian/narrf';

import col from '_app/components/columns';



export default function AdminPatientTable() {
    const api = useApi();
    const [init, setInit] = useState(false);
    const tableRef = useRef();

    const facilityColumn = col.useUserFacility(
        'data.attributes.userinfo.facility_ids'
    );

    useEffect(() => {
        if(!init) {
            setInit(true);
            api.fetchAllJsonApi(api.directory.data.links['pcc.facilities']);
        }
    }, [api, init, setInit]);

    const fetchData = async options => {
        const result = await api.fetchMaterialTable(
            api.directory.data.links['pcc.users'], {
                ...options,
            }
        );
        return result;
    }

    const refresh = () => tableRef.current.onQueryChange();

    const onRowUpdate = async (rowData, oldRowData) => {
        const {data} = rowData;
        await api.fetchJson(data.links.self, {
            method: "PATCH",
            json: {data}
        });
    }

    const columns = [
        {title: 'Email', field: 'data.attributes.userinfo.email', editable: false},
        {title: 'Name', field: 'data.attributes.userinfo.name', editable: false},
        facilityColumn,
    ];

    return (
        <MaterialTable 
            title="Organization Users"
            tableRef={tableRef}
            columns={columns}
            data={fetchData}
            editable={{onRowUpdate}}
            options={{
                search: false,
                filtering: true,
            }}
            detailPanel={[
            ]}
            actions={[
               {
                  icon: 'refresh',
                  tooltip: 'Refresh table',
                  isFreeAction: true,
                  onClick: refresh
                },
            ]}
        />
    );
}
