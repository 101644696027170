import React, {Fragment, useRef, useState, useEffect} from 'react';
import fp from 'lodash/fp';
import {useHistory} from 'react-router-dom';

import {makeStyles} from '@material-ui/core';
import UriTemplate from 'uritemplate';

import {useApi, components} from '@arborian/narrf';

import {DateTime} from '_app/components/DateTime';

const {
    DataTable,
    Column,
    Action,
    DetailPanel,
    useLocationFetchOptions,
    updateLocationFetchOptions,
} = components;

const useStyles = makeStyles(theme => ({
    details: {
        padding: theme.spacing(1),
    },
}));

function ProgressNoteDetails({artifact}) {
    const classes = useStyles();
    const sections = fp.get('attributes.pcc.sections', artifact);
    console.log('My sections', artifact, sections);
    return (
        <div className={classes.details}>
            {fp.map(
                s => (
                    <Fragment key={s.name}>
                        <h6>{s.name}</h6>
                        {s.value}
                    </Fragment>
                ),
                sections,
            )}
        </div>
    );
}

export default function ProgressNotesTable({patient, ...props}) {
    const api = useApi();
    const tableRef = useRef();
    const history = useHistory();
    const defaultFetchOptions = {
        sort: {field: 'attributes.pcc.effectiveDate', direction: 'desc'},
    };
    const fetchOptions = useLocationFetchOptions(defaultFetchOptions, 'table.');
    const [init, setInit] = useState(false);
    const [progressNoteTypeLookup, setProgressNoteTypeLookup] = useState();
    const fetch = async o => {
        const url = UriTemplate.parse(
            api.directory.data.links['artifact.artifacts'],
        ).expand({patientId: patient.id, atype: 'progressNote'});
        let resp = await api.fetchDataTable(url, o);
        return resp;
    };

    useEffect(() => {
        if (!init) {
            setInit(true);
            const params = {
                orgUuid: fp.get('attributes.pcc.orgUuid', patient),
                facId: fp.get('attributes.pcc.facId', patient),
            };
            const url = UriTemplate.parse(
                api.directory.data.links['pcc.progressNoteTypes'],
            ).expand(params);
            api.fetchJson(url).then(noteTypes => {
                console.log({noteTypes});
                setProgressNoteTypeLookup(
                    fp.pipe([fp.map(pnt => [pnt, pnt]), fp.fromPairs])(
                        noteTypes,
                    ),
                );
            });
        }
    }, [init, api, patient]);
    if (!patient) return null;

    const onChangeFetchOptions = o => {
        const newParams = updateLocationFetchOptions(o, 'table.');
        history.push('?' + newParams.toString());
    };
    if (!progressNoteTypeLookup) {
        return <div>Loading...</div>;
    }
    return (
        <DataTable
            title='Progress Notes'
            tableRef={tableRef}
            data={fetch}
            fetchOptions={fetchOptions}
            onChangeFetchOptions={onChangeFetchOptions}
            options={{filtering: true}}
        >
            <Column
                title='Effective Date'
                field='attributes.pcc.effectiveDate'
                type='date'
                render={row => (
                    <DateTime
                        value={fp.get('data.attributes.pcc.effectiveDate', row)}
                    />
                )}
            />
            <Column
                title='Type'
                field='attributes.pcc.progressNoteType'
                lookup={progressNoteTypeLookup}
            />
            <Action
                free
                onClick={() => tableRef.current.fetch()}
                tooltip='Refresh'
                icon='refresh'
            />
            <DetailPanel
                render={row => {
                    console.log('Render detail', row);
                    return <ProgressNoteDetails artifact={row.data} />;
                }}
            />
        </DataTable>
    );
}
/*
        <MaterialTable
            title='Progress Notes'
            data={fetchOld}
            columns={columns}
            options={{
                filtering: true,
            }}
            detailPanel={artifact => (
                <ProgressNoteDetails artifact={artifact} />
            )}
            {...props}
        />
    );
}
*/
