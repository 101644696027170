import React from 'react';
import {useSelector} from 'react-redux';
import {createSelector} from '@reduxjs/toolkit';
import fp from 'lodash/fp';

import {ducks} from '@arborian/narrf';

import ChipList from '_app/components/ChipList';

import tableColumn from './tableColumn';

const selectRolesByName = createSelector(
    ducks.jsonapi.selectObject('Role'),
    fp.pipe([
        fp.values,
        fp.map(role => ([role.attributes.name, role])),
        fp.fromPairs
    ])
);

const selectRoleLookup = createSelector(
    selectRolesByName, roles => {
        return fp.pipe([
            fp.toPairs,
            fp.map(
                ([name, role]) => ([name, fp.get('attributes.description', role)])
            ),
            fp.fromPairs
        ])(roles);
    }
);


function RoleList({value, onChange}) {
    const roles = useSelector(selectRolesByName);
    const valueOptions = fp.map(
        item => fp.get(item, roles),
        value
    );
    const handleChange = newValue => {
        const roleNames = fp.pipe([
            fp.map(fp.get('attributes.name')),
            fp.filter(fp.identity)
        ])(newValue);
        onChange(roleNames);
    }

    return (
        <ChipList value={valueOptions} onChange={onChange && handleChange} options={roles}
            addLabel="Add role"
            getOptionLabel={fp.get('attributes.description')}
            getOptionSelected={(o, value) => {
                const name = fp.get('attributes.name', o);
                return !fp.includes(name, value);
            }}
        />
    );
}

export default function useUserFacilityColumn(props={}) {
    const lookup = useSelector(selectRoleLookup);

    return tableColumn({
        title: 'Roles',
        field: 'data.attributes.roles',
        component: RoleList,
        sorting: false,
        lookup,
        ...props
    });
}
