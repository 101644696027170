import React from 'react';
import * as I from '@material-ui/icons';
import {makeStyles} from '@material-ui/core';

import TelephoneButton from '_app/components/TelephoneButton';

const useStyles = makeStyles(theme => ({
    phoneBlock: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        '& button': {
            margin: theme.spacing(1, 0),
        }
    },
}));

const useButtonStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
    }
}));

export default function PhonesBlock({value}) {
    const classes = useStyles();
    const buttonClasses = useButtonStyles();
    if(!value) return null;
    return (
        <div className={classes.phoneBlock}>
            {value.phone && (
                <TelephoneButton variant="outlined" value={value.phone} icon={<I.Business/>} 
                    classes={buttonClasses}
                />
            )}
            {value.fax && (
                <TelephoneButton variant="outlined" value={value.phone} icon={<I.Print/>} 
                    classes={buttonClasses} href={`fax:${value.fax}`} 
                />
            )}
        </div>
    );
}
