import React from 'react';

import InfoCard from '_app/components/InfoCard';
import {ForbiddenAvatar} from '_app/art/MedicalIcons';

export default function AllergiesCard(props) {
    return (
        <InfoCard color='blue' Avatar={ForbiddenAvatar} title="Allergies" {...props}>
        </InfoCard>
    )
}
