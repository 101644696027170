import React from 'react';

import VitalCard from '_app/components/VitalCard';
import {DropAvatar} from '_app/art/MedicalIcons';

export default function BloodSugarCard({patient, pcc}) {
    if(!pcc) return null;
    return (
        <VitalCard color='blue' Avatar={DropAvatar} type="bloodSugar" patient={patient} {...pcc}>
            <h6>Blood sugar</h6>
        </VitalCard>
    )
}
