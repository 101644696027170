import React, {useMemo}from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';
import MaterialTable from 'material-table';
import UriTemplate from 'uritemplate';

import {useApi, ducks} from '@arborian/narrf';
import {DateTime} from '_app/components/DateTime';

const consentStatusLookup = {
    'TBD': 'TBD',
    'Consented': 'Consented',
    'Refused': 'Refused',
    'Historical': 'Historical',
    'notEligible': 'Not Eligible',
}

const columns = timeZone => ([
    {title: 'Name', field: 'data.attributes.pcc.immunization'},
    {title: 'Consent', field: 'data.attributes.pcc.consentStatus', lookup: consentStatusLookup},
    {
        title: 'Created', field: 'data.attributes.pcc.createdDateTime',
        render: row => <DateTime value={fp.get('data.attributes.pcc.createdDateTime', row)}/>,
        type: 'datetime', filtering: false,
    },
    {
        title: 'Administered', field: 'data.attributes.pcc.administrationDateTime',
        render: row => <DateTime value={fp.get('data.attributes.pcc.administrationDateTime', row)}/>,
        type: 'datetime', filtering: false,
    },
])

export default function ImmunizationTable({patient, ...props}) {
    const api = useApi();
    const fetch = async o => {
        const url = UriTemplate.parse(
            api.directory.data.links['artifact.artifacts']
        ).expand({patientId: patient.id, atype: 'immunization'});
        let resp = await api.fetchMaterialTable(url, o);
        return resp;
    }

    const rel = useSelector(ducks.jsonapi.selectRelated(patient));

    const memoColumns = useMemo(() => {
        const timeZone = fp.get('attributes.timeZone', rel.facility);
        return columns(timeZone);
    }, [rel]);
    if(!patient) return null;
        

    return (
        <MaterialTable 
            title="Immunizations"
            data={fetch}
            columns={memoColumns}
            options={{
                filtering: true
            }}
            {...props}
        />
    );
}
