import React from 'react';

import {Hidden, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    normal: {
        height: 96,
    },
    small: {
        height: 32,
    },
}));

export default function Logo() {
    const classes = useStyles();
    return (
        <>
            <Hidden xsDown>
                <img alt="logo" className={classes.normal} src="/img/web-logo.png"/>
            </Hidden>
            <Hidden smUp>
                <img alt="logo" className={classes.small} src="/img/cf-icon.svg"/>
            </Hidden>
        </>
    );
}

