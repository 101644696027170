import React, {useEffect, useState, useMemo, useRef, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import fp from 'lodash/fp';
import {useDispatch, useSelector} from 'react-redux';

import MaterialTable from 'material-table';
import {Button} from '@material-ui/core';

import {useApi, ducks} from '@arborian/narrf';

import {url_for} from '_app/routes';
import ConfirmationDialog from '_app/components/ConfirmationDialog';
import PatientAvatar from '_app/components/PatientAvatar';
import AuthorizedUserTable from '_app/components/AuthorizedUserTable';
import InvitationTable from '_app/components/InvitationTable';
import {selectFacilityNames} from '_app/duck';

const linkStatusLookup = {
    linked: 'Linked',
    unlinked: 'Unlinked',
    link_requested: 'Link requested',
    unlink_requested: 'Unlink requested',
    linking: 'Linking',
    unlinking: 'Unlinking',
}

function LinkButton({patient}) {
    const history = useHistory();
    const dispatch = useDispatch();
    const value = fp.get('attributes.sync_status.pcc', patient);
    const onClick = () => {
        dispatch(ducks.jsonapi.clearData());
        history.push(url_for('patientHome', {patientId: patient.id}))
    }
    return (
        <Button onClick={onClick} disabled={value !== 'linked'} variant="outlined" color="primary">
            {value}
        </Button>
    );
}


export default function AdminPatientTable() {
    const api = useApi();
    const [init, setInit] = useState(false);
    const [dialogProps, setDialogProps] = useState({ message: null, onConfirm: null });
    const tableRef = useRef();

    useEffect(() => {
        if(!init) {
            setInit(true);
            api.fetchAllJsonApi(api.directory.data.links['pcc.facilities']);
        }
    }, [api, init, setInit]);
    const facilityLookup = useSelector(selectFacilityNames);

    const columns = useMemo(() => [
        {title: '', render: rowData => <PatientAvatar patient={rowData.data}/>},
        {
            title: 'Sync Status', field: 'data.attributes.sync_status.pcc', defaultFilter: ['linked'],
            render: row => <LinkButton patient={row.data}/>,
            lookup: linkStatusLookup
        },
        {title: 'Last Name', field: 'data.attributes.pcc.lastName'},
        {title: 'First Name', field: 'data.attributes.pcc.firstName'},
        {title: 'Birth Date', field: 'data.attributes.pcc.birthDate', type: 'date'},
        {title: 'Facility', field: 'data.attributes.s_facId', type: 'numeric', lookup: facilityLookup},
    ], [facilityLookup]);

    const fetchData = async options => {
        const result = await api.fetchMaterialTable(
            api.directory.data.links['patient.patients'],
            {...options, include: ['photo']},
        );
        return result;
    }

    const refresh = () => tableRef.current.onQueryChange();

    const setPatientLink = useCallback(async (rowData, requested) => {
        const data = fp.set('attributes.sync_status.pcc', requested, rowData.data);
        await api.fetchJsonApi(rowData.data.links.self, {
            method: 'PATCH',
            json: {data}
        });
        refresh();
    }, [api]);

    const handleDialogClose = useCallback(
        () => setDialogProps({message: null, onConfirm: null}),
        [setDialogProps]
    )

    const handleLinkPatient = useCallback(async (ev, rowData) => {
        setDialogProps({
            message: 'Are you sure you want to link the patient?',
            onConfirm: () => setPatientLink(rowData, 'link_requested')
        });
    }, [setPatientLink, setDialogProps]);
        
    const handleUnlinkPatient = useCallback(async (ev, rowData) => {
        setDialogProps({
            message: 'Are you sure you want to unlink the patient?',
            onConfirm: () => setPatientLink(rowData, 'unlink_requested')
        });
    }, [setPatientLink, setDialogProps]);

    /*
    const setPatientLink = requested => async (ev, rowData) => {
        setDialogConfirm(async () => {
            const data = fp.set('attributes.sync_status.pcc', requested, rowData.data);
            await api.fetchJsonApi(rowData.data.links.self, {
                method: 'PATCH',
                json: {data}
            });
            refresh();
        })
        setDialogMessage(`Are you sure you want to set the patient status to ${requested}?`)
    }
    */

    const linkStatus = fp.get('data.attributes.sync_status.pcc')

    return (
        <>
            <ConfirmationDialog open={!!dialogProps.message} onClose={handleDialogClose} 
                message={dialogProps.message} onConfirm={dialogProps.onConfirm}
            />
            <MaterialTable 
                title="Patient Administration"
                tableRef={tableRef}
                columns={columns}
                data={fetchData}
                options={{
                    filtering: true,
                }}
                detailPanel={[
                    row => ({
                        tooltip: 'Authorized users',
                        icon: 'people_outline', openIcon: 'people',
                        disabled: linkStatus(row) !== 'linked',
                        render: row => <AuthorizedUserTable patient={row.data} />
                    }),
                    row => ({
                        tooltip: 'Invitations',
                        icon: 'mail_outline', openIcon: 'mail',
                        disabled: linkStatus(row) !== 'linked',
                        render: row => <InvitationTable patient={row.data} />
                    })
                ]}
                actions={[
                   {
                      icon: 'refresh',
                      tooltip: 'Refresh table',
                      isFreeAction: true,
                      onClick: refresh
                    },
                    rowData => ({
                        icon: 'link',
                        hidden: linkStatus(rowData) === 'linked',
                        disabled: linkStatus(rowData) !== 'unlinked',
                        tooltip: 'Link patient',
                        onClick: handleLinkPatient,
                        // setPatientLink('link_requested'),
                    }),
                    rowData => ({
                        icon: 'link_off',
                        hidden: linkStatus(rowData) !== 'linked',
                        tooltip: 'Unlink patient',
                        onClick: handleUnlinkPatient,
                        // setPatientLink('unlink_requested'),
                    }),

                ]}
            />
        </>
    );
}
