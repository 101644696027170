import React, {useEffect, useState, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import fp from 'lodash/fp';

import {makeStyles} from '@material-ui/core';

import {useApi, ducks} from '@arborian/narrf';

import BasePage from '_app/components/BasePage';
import PccLoginButton from '_app/components/PCCLoginButton';
import BaseLoginButton from '_app/components/BaseLoginButton';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flex: 1,
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
    },
}));

export default function LoginPage() {
    const api = useApi();
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    const history = useHistory();
    const classes = useStyles();
    const [providers, setProviders] = useState();
    const {state} = useLocation();
    console.log('LoginPage state', state);

    const fetchProviders = useCallback(async () => {
        const redirect_uri = new URL('/callback', window.location.href);
        const authorizeLink = api.authorizeLink({redirect_uri, intent: 'login', state});
        try {
            let rv = await fetch(authorizeLink);
            if(rv.status === 300) {
                let data = await rv.json();
                console.log('Got data', data);
                const providers = fp.pipe([
                    fp.get('options'),
                    fp.toPairs,
                    fp.map(
                        ([id, attributes]) => ({id, attributes})
                    )
                ])(data);
                console.log('got providers', providers);
                setProviders(providers);
            }
        } catch(e) {
            console.log('Got error, trying direct redirect')
            window.location = authorizeLink;
        }
    }, [api, state, setProviders]);

    useEffect(() => {
        if(userinfo && state && state.next) {
            history.push(state.next);
        }
        if(!providers) {
            fetchProviders();
        }
    }, [history, state, userinfo, providers, fetchProviders]);

    const pcc = fp.find(p => p.attributes.plugin_id === 'pcc', providers);
    const cognito = fp.find(p => p.attributes.plugin_id === 'aws-cognito', providers);
    const google = fp.find(p => p.attributes.plugin_id === 'google', providers);
    
    return (
        <BasePage subtitle="Login">
            <div className={classes.root}>
                <PccLoginButton provider={pcc} intent="login"/>
                <BaseLoginButton provider={google} state={state} intent="login">
                    Sign in with Google
                </BaseLoginButton>
                <BaseLoginButton provider={cognito} state={state} intent="login">
                    Sign in with Password
                </BaseLoginButton>
            </div>
        </BasePage>
    )
}

