import React from 'react';
import fp from 'lodash/fp';

import {MESSAGE_TYPES} from '_app/constants';
import {DateTime} from '_app/components/DateTime';

const lookupMessageTypes = fp.pipe([
    fp.map(o => [o.value, o.label]),
    fp.fromPairs,
])(MESSAGE_TYPES);

export default function MessageDetailPanel({message}) {
    const typeId = fp.get('attributes.type', message);
    const typeLabel = fp.get(typeId, lookupMessageTypes);
    return (
        <div>
            <strong>Type:</strong> {typeLabel}
            <br />
            <strong>Sender:</strong> {fp.get('attributes.from_', message)}
            <br />
            <strong>Subject:</strong> {fp.get('attributes.subject', message)}
            <br />
            <strong>Sent:</strong>{' '}
            <DateTime value={fp.get('attributes.timestamp', message)} />
            <br />
            <p>{fp.get('attributes.text', message)}</p>
        </div>
    );
}
