const policy = `
# Carefolio, Inc | Privacy Policy

_Last Updated Date: June 15, 2019_

Carefolio, Inc. (“Carefolio” or “we”) provides this Privacy Policy to inform you of our policies and procedures regarding the collection, use and disclosure of personal information we receive from users of our website, accessible at carefolio.com (“Site”), and our mobile applications (collectively, “Carefolio App”) as well as use of our proprietary set of services made available through the same (“Carefolio Services”). This Privacy Policy applies to your access to and use of the Site, the Carefolio App and the Carefolio Services, and by choosing to use them, you consent to the collection, transfer, processing, storage, disclosure and other uses of your information described in this Privacy Policy.

As used in this policy, the terms “using” and “processing” information include using cookies on a computer, subjecting the information to statistical or other analysis and using or handling information in any way, including, but not limited to collecting, storing, evaluating, modifying, deleting, using, combining, disclosing and transferring information within our organization or among our affiliates within the United States or internationally.

As used below, the term “Personal Information” is used to indicate any piece of information or data that can be used to identify any individual. Personal Information may relate to you as a Member, or it may relate to the individual that is the subject of any Profile that you create, if you create a Profile for a child, parent or other loved one.

Any other defined term that is not defined in this Privacy Policy has the meaning given that term in the Carefolio Terms of Service or any Additional Terms of Service, as applicable.

# MODIFICATIONS

Carefolio reserves the right, at its sole discretion, to modify this Privacy Policy, at any time and without prior notice. If we modify this Privacy Policy, we will post the modified Privacy Policy and alert you to the modified Privacy Policy on the Site and the Carefolio App or will otherwise provide you with notice of the modification. We will also update the “Effective Date” at the top of this Privacy Policy. By continuing to access or use the Site, the Carefolio App or the Carefolio Services after we have posted a modified Privacy Policy or have provided you with notice of a modification, you are indicating that you agree to be bound by the modified Privacy Policy. If the modified Privacy Policy is not acceptable to you, your only recourse is to cease using the Site, Carefolio App and Carefolio Services. Information we collect under the old Privacy Policy will remain subject to the old Privacy Policy unless and until you consent to the application of any modified Privacy Policy to such information.

# THE INFORMATION WE COLLECT

## Personal Information

- You may browse the Site and download the Carefolio App without expressly providing us any Personal Information that expressly identifies you, however, in order to make full use of the Carefolio Services as made available via the Site and/or the Carefolio App, you will be required to register with us. When you register with us to create an account and become a member, we will ask you for certain Personal Information. In order to set up your account, you need to provide your email address and you will need to select a password. If you elect to add any other Personal Information to your Account we will collect that information and use it and share it as set forth below.
- As a Member, you may also set up one or more Profiles within the Carefolio Services. The Profile may be personal to you and your healthcare or the subject of the Profile may be a child, parent or other loved one. When you set up a Profile, you will be asked to provide Personal Information about the individual that is the subject of the Profile, whether yourself or your child, parent or other loved one. When you set up a Profile in the Carefolio Services. we will collect and store any Information that you may elect to provide to us as part of the Profile Content, which may include but is not limited to medication information, gender, date of birth, personal contacts, photographs and files, notes on your symptoms and other journal information, medical treatment instructions, medical appointments, insurance information, your doctor’s address information, advance directives, and prescription refill information. In the event you set up a Profile that relates to someone other than yourself, you are solely responsible for ensuring that you have any and all consents and permissions to share that Personal Information with us and to use that information as part of the Carefolio Services.
- You may provide us with Personal Information of a third party Caregiver, as contemplated by the Carefolio Terms of Service, to access a Profile that you create. If you elect to invite any third party to access a Profile connected to your Account, we will ask you for such third party’s name and email address. We will send the third party emails inviting him or her to register for the Carefolio Services. We store this information for the sole purpose of sending this one-time email. The third party may contact us at <support@carefol.io> to request that we remove this information from our database. We cannot control whether such third party will elect to register for the Carefolio Services.

## Non-Personal Information
We also collect the following information any time you visit our Site or the Carefolio App, as applicable. This information may not necessarily identify you, but if we have your Personal Information, or you otherwise set up an Account with us, we may link any of the below non-personal information with your Personal Information in our records.

- Browser Log Data. When you visit the Site, whether as a Member or a non-registered user just browsing the Site, our servers automatically record information that your browser sends whenever you visit a website (“Browser Log Data”). This Browser Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser type, operating system or the webpage you were visiting before you came to our Site, pages of our Site that you visit, the time spent on those pages, access times and dates, and other statistics. We use this information to monitor and analyze use of the Site and for the Site’s technical administration, to increase our Site’s functionality and user-friendliness, and to better tailor it to our visitors’ needs. For example, we use this information to verify that visitors to the Site meet the criteria required to process their requests. We do not treat Browser Log Data as Personal Information or use it in association with other Personal Information, though we may aggregate, analyze and evaluate such information for the same purposes as stated above regarding other Non-Identifying Information.
- Cookies. The Site may use “cookies” to collect information. A cookie is a small data file that we transfer to your computer’s hard disk for record-keeping purposes. We use cookies for two purposes. First, we utilize persistent cookies to save your login information for future logins to the Site. Second, we utilize session ID cookies to enable certain features of the Site, to better understand how you interact with the Site and to monitor aggregate usage by users and web traffic routing on the Site. Unlike persistent cookies, session cookies are deleted from your computer when you log off from the Site and then close your browser. You can instruct your browser, by changing its options, to stop accepting cookies or to prompt you before accepting a cookie from the websites you visit. If you do not accept cookies, however, you may not be able to use all portions or functionalities of the Site.
- App Usage Data. When you use the Carefolio App, whether as a Member or a non-registered user, our servers automatically record information that your mobile device sends whenever you use an Internet service. (“App Usage Data”). This App Usage Data may include information such as your computer’s IP address, device model and version, device identifier, operating system, time zone, name of your mobile operator or ISP, application performance measurements, and error conditions. It may also include a record of when specific features of the Carefolio App or Carefolio Service are used. We use this information to monitor and analyze use of the Carefolio App and Carefolio Service and for their technical administration, to increase their functionality and user-friendliness, and to better tailor them to our visitors’ needs. For example, we use this information to measure the popularity of different features and to more effectively resolve customer issues. We may aggregate, analyze and evaluate such information for the same purposes as stated above regarding other Non-Identifying Information.
- Location Information. The App, with your consent, may access your location. Carefolio will only access your location services while you are using the App and provided you have opted in to activate this feature. You may choose to allow Carefolio to access your location by granting the App access to your device’s location, when prompted by the App, or through your device’s location services settings. Carefolio uses the Google Places API(s) for location searches and as a result is using the Google Maps/Google Earth API(s). Pursuant to the Google Maps/Google Earth APIs Terms of Service, use of this location feature is also subject to the Google privacy policy, which can be found at http://www.google.com/privacy.html, and is incorporated with this reference.

# HOW WE USE YOUR INFORMATION

- We do not sell your Personal Information.
- We will use any Personal Information in your Account as well as information in your Profile to provide the Carefolio Services, complete your transactions, administer your inquiries, and present you with offers for products and services that we think may be of use or interest to you. We may contact you in various ways including but not limited to, via email, Inbox in the app, telephone, cell phone, and SMS/text messages.
- If you set up a medications list in a Profile by scanning or adding any existing prescription medications to a Profile, and you have elected to share your location with us, we can help you locate the contact information and/or address of the prescribing physician or practice, which you can then elect to add to the applicable Profile.
- If you add Personal Information about prescriptions into a Profile, we may use that information to present you with offers for pharmacy services, including without limitation our Pharmacy Assistance Carefolio Service. If you register to receive our Pharmacy Assistance Carefolio Service, we may need additional Personal Information for the applicable Profile, which you may not have elected to provide us previously, such as date of birth, insurance information, credit card information, driver’s license copy, and contact information for any current pharmacy. If you elect to receive our Pharmacy Assistance Carefolio Service, we will use all Personal Information as necessary to provide you the Pharmacy Assistance Service, including sharing it with the applicable pharmacy as further set forth below. By registering to receive Pharmacy Assistance Carefolio Service, you consent to receive emails or other communications from us pertaining to your prescriptions and related care.
- We have a team of Care Advisors to facilitate use of the site and Carefolio app. Care Advisors may use your name, address, email, phone number, gender and birthdate (“Contact Information”), insurance information and App Usage Data to contact you about various health related products and service such as pharmacy services and insurance.
- We may aggregate and combine all Personal Information that we receive through your Member Accounts and your Profiles with the information of other Members and Profiles. We use this anonymous information to attempt to provide you with a better experience, to improve the quality and value of our services and to analyze and understand how our Site, Carefolio App and Carefolio Services are used.

# INFORMATION SHARING AND DISCLOSURE

- **Caregivers**. In the event that a third party Caregiver registers for access to the Carefolio Services pursuant to an invitation issued by your Account, we will provide them with access to all Profiles related to such Account. We cannot control how any Caregiver may use or disclose or otherwise exploit any Personal Information or other Profile Content that you may elect to make available to such third party. You expressly release Carefolio from any responsibility and liability resulting from any third party access to any Personal Information or Profile Content that you may permit. Any information you choose to provide and make available to third parties via the Carefolio Services should reflect how much you want others to know about you and the individual(s) whose information is in your Profile.
- **Healthcare Providers**. You authorize Carefolio to request and receive protected health information (PHI) from healthcare providers you may have seen in the past to assist us in organizing and presenting your information to you.
- **Other Third Parties**. If you elect to use or otherwise take advantage of some of the other third party services that we may provide and make available to you, we will share certain of your information with such third party and will disclose to you at the time what information would be necessary to share with such third party.
- **Aggregate Information and Non-Identifying Information**. We may share aggregated information that does not include Personal Information and we may otherwise disclose non-Personal Information with third parties for industry analysis, demographic profiling and other purposes.
- **Service Providers**. We will disclose Personal Information to the third party service providers that we engage to assist us in making the Site, Carefolio App and Carefolio Services available. These service providers consist of our datacenter provider, storage provider, customer support system provider, email service providers, and software development contractors. These third parties have access to your Personal Information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
- **Compliance with Laws and Law Enforcement**. While Carefolio maintains information regarding your health on your behalf, Carefolio is not a covered entity under the Health Insurance Portability and Accountability Act (HIPAA). However, we take the protection of your private health information very seriously. In the event that we receive any subpoena or similar request from a court, government or law enforcement official, we will only disclose any Personal Information about you or other information in our custody or control as we, in our sole discretion, believe necessary or appropriate to respond to claims and legal process (including but not limited to subpoenas), to protect the property and rights of Carefolio or a third party, to protect the safety of the public or any person, or to prevent or stop activity we may consider to be, or to pose a risk of being, illegal, unethical or legally actionable activity. Given the sensitivity of the Personal Information within your Profiles, it is our policy to carefully review any such request for information in our systems and we will decline to comply with any such request in our discretion.
- **Business Transfers**. Carefolio may sell, transfer or otherwise share some or all of its assets, including your Personal Information and related Account, Profiles and Profile Content, in connection with a merger, acquisition, reorganization or sale of assets or in the event of bankruptcy.

# CHANGING OR DELETING YOUR INFORMATION

All Members may review, update, correct or delete their Account information and any associated Personal Information or Profile Content by contacting us or by accessing the Carefolio Services. If you would like to close your Account or delete any Profile from your Account, please contact us at <support@carefol.io>. We will use commercially reasonable efforts to honor your request. We will retain an archived copy of your records as set forth below.

# DATA RETENTION

We will retain the information you provide via our Site or the Carefolio App for as long as your Account is active or as needed to provide you with the Carefolio Services. We will also retain and use the information you provide via our Site or Carefolio App, as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements. If you delete your Account or any Profile, any Personal Information associated with the same is moved out of our active databases, but will still be retained in our systems pursuant to our data backup and retention policies.

# DO NOT TRACK SIGNALS AND AD NETWORKS

Our Site does not have the capability to respond to “Do Not Track” signals received from various web browsers.

# SECURITY – HOW WE PROTECT YOUR DATA

All communications between you and the Site, and between the App and the Site, are encrypted using the highest level encryption supported by your device. Data stored in the app and on the Site is encrypted at rest using industry-standard AES encryption.

# IDENTITY THEFT

Identity theft is of great concern to Carefolio. Safeguarding information to help protect you from identity theft is a top priority. We do not and will not, at any time, request your credit card information, your login information, or national identification numbers in a non-secure or unsolicited e-mail or telephone communication. For more information about identity theft, visit the Federal Trade Commission’s website at www.ftc.gov.

# SECURITY BREACH

The Site, Carefolio App and Carefolio Services rely on the Internet and mobile networks, which are not always secure. We use commercially reasonable efforts to maintain the confidentiality, availability and integrity of any information we collect, but we cannot assure that the use of the Site, Carefolio App or Carefolio Services are secure or that your Personal Information or any other data that we may have will be secure. In the event we become aware of a security incident, we will notify you to the extent required under applicable law.

# REPORTING A PROBLEM

If you feel your account may have been compromised, or you discover abuse or misuse of Carefolio Services, the Site or the Carefolio App, please report it immediately to <support@carefol.io> and we’ll investigate.

If you’re a security researcher who’s discovered an issue, please see our security response page for our reporting procedure.

# INTERNATIONAL TRANSFER

Your information may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the privacy laws may not be as protective as those in your jurisdiction. If you are located outside the United States and choose to provide information to us, Carefolio transfers Personal Information to the United States and processes it there. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.

# LINKS TO OTHER WEBSITES

Our Site may contain links to other websites. If you choose to click on a third party link, you will be directed to that third party’s website. The fact that we link to a website is not an endorsement, authorization or representation of our affiliation with that third party, nor is it an endorsement of their privacy or information security policies or practices. We do not exercise control over third party websites. These other websites may place their own cookies or other files on your computer, collect data or solicit personal information from you. Other websites follow different rules regarding the use or disclosure of the personal information you submit to them. We encourage you to read the privacy policies or statements of the other websites you visit.

# OUR POLICY TOWARD CHILDREN

The Site and Carefolio Apps are not directed to persons under 13. We do not knowingly collect personally identifiable information from children under 13. If a parent or guardian becomes aware that his or her child has provided us with Personal Information without their consent, he or she should contact us at <support@carefol.io>. If we become aware that a child under 13 has provided us with Personal Information, we will delete such information from our files. If you have created a Profile for a minor under the age of 18, you represent and warrant to us at Carefolio that you are the parent or legal guardian for that minor with a right to provide us the Personal Information of that minor.

# CONTACTING Carefolio

If you have any questions about this Privacy Policy, please contact Carefolio at <support@carefol.io>

`;
export default policy;
