import React from 'react';
import {useSnackbar} from 'notistack';

import {Button, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    button: {
        color: 'white'
    }
}));

export default function CloseSnackbarButton({notiKey}) {
    const classes = useStyles();
    const {closeSnackbar} = useSnackbar();
    return (
        <Button className={classes.button} onClick={() => closeSnackbar(notiKey)}>
            Dismiss
        </Button>
    )
}
