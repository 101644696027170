import React from 'react';
import MaterialTable from 'material-table';
import UriTemplate from 'uritemplate';

import {useApi} from '@arborian/narrf';

const clinicalStatusLookup = {
    active: 'Active',
    resolved: 'Resolved',
    priorHistory: 'Prior History',
}

const typeLookup = {
    'Allergy': 'Allergy',
    'Intolerance': 'Intolerance',
    'Propensity to Adverse Reactions': 'Adv. Reaction',
    'Unknown': 'Unknown',
}

const catLookup = {
    'Drug': 'Drug',
    'Food': 'Food',
    'Environmental': 'Environmental',
    'Substance': 'Substance',
    'Other': 'Other',
}

const columns = [
    {
        title: 'Status', field: 'data.attributes.pcc.clinicalStatus', lookup: clinicalStatusLookup,
        defaultFilter: ['active'],
    },
    {
        title: 'Onset Date', field: 'data.attributes.pcc.onsetDate', type: 'date',
    },
    {
        title: 'Resolved Date', field: 'data.attributes.pcc.resolvedDate', type: 'date',
    },
    {title: 'Allergen', field: 'data.attributes.pcc.allergen'},
    {title: 'Type', field: 'data.attributes.pcc.type', lookup: typeLookup},
    {title: 'Category', field: 'data.attributes.pcc.category', lookup: catLookup},
]

export default function AllergyTable({patient, ...props}) {
    const api = useApi();
    if(!patient) return null;
    const fetch = async o => {
        const url = UriTemplate.parse(
            api.directory.data.links['artifact.artifacts']
        ).expand({patientId: patient.id, atype: 'allergyIntolerance'});
        let resp = await api.fetchMaterialTable(url, o);
        return resp;
    }
    return (
        <MaterialTable 
            title="Allergies"
            data={fetch}
            columns={columns}
            options={{
                filtering: true
            }}
            {...props}
        />
    );
}
