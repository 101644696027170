import React from 'react';

import {makeStyles} from '@material-ui/core';

import PCCLoginButton from '_app/components/PCCLoginButton';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2)
    },
}));

export default function PCCLoginForm({state, provider, intent='login'}) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <h6>Healthcare Professionals</h6>
            <div className={classes.vbox}>
                <PCCLoginButton state={state} provider={provider} intent={intent}/>
            </div>
        </div>
    )
}
