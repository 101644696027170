import React, {useState} from 'react';

import {makeStyles} from '@material-ui/core';

import BasePage from '_app/components/BasePage';
import AppBar from '_app/components/AppBar';
import NavDrawer from '_app/components/NavDrawer';
import Footer from '_app/components/Footer';
import AuthInfo from '_app/components/AuthInfo';

const useStyles = makeStyles(theme => ({
    vbox: {
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.constants.drawerWidth
        },
        display: 'flex',
    },
    contentArea: {
        flex: 1,
        padding: theme.spacing(2),
    },
    spacer: {
        flex: 1
    },
}));


export default function AdminPage({title, children}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    return (
        <BasePage>
            <NavDrawer open={open} onClose={() => setOpen(false)}/>
            <AppBar onClickLogo={() => setOpen(true)}>
                {title && <h6>{title}</h6>}
                <div className={classes.spacer}/>
                <AuthInfo />
            </AppBar>
            <div className={classes.vbox}>
                <div className={classes.container}>
                    <div className={classes.contentArea}>
                        {children}
                    </div>
                </div>
                <Footer />
            </div>
        </BasePage>
    );
}
