import React from 'react';
import {useSelector} from 'react-redux';
import {createSelector} from '@reduxjs/toolkit';
import fp from 'lodash/fp';

import {ducks} from '@arborian/narrf';

import ChipList from '_app/components/ChipList';

import tableColumn from './tableColumn';

const facilityLabel = fac => {
    const pcc = fp.get('attributes.pcc', fac);
    const value = `${pcc.facilityName} (${pcc.orgName})`
    return value;
}

const selectFacilitiesByFacId = orgUuid => createSelector(
    ducks.jsonapi.selectObject('PCCFacility'),
    fp.pipe([
        fp.values,
        fp.map(fac => {
            const pcc = fp.get('attributes.pcc', fac);
            const key = `${pcc.orgUuid}/${pcc.facId}`
            return [key, fac]
        }),
        fp.fromPairs
    ])
);

const selectFacilityLookup = createSelector(
    ducks.jsonapi.selectObject('PCCFacility'), facilities => {
        return fp.pipe([
            fp.toPairs,
            fp.map(([id, fac]) => {
                const pcc = fp.get('attributes.pcc', fac);
                const key = `${pcc.orgUuid}/${pcc.facId}`
                return [key, facilityLabel(fac)];
            }),
            fp.fromPairs
        ])(facilities);
    }
);


function FacilityList({value, rowData, onChange, ...props}) {
    const orgUuid = fp.get('data.attributes.userinfo.orgUuid', rowData);
    const facilities = useSelector(selectFacilitiesByFacId(orgUuid));
    console.log({value, rowData, onChange, props})

    const valueOptions = fp.map(
        facId => fp.get(`${orgUuid}/${facId}`, facilities),
        value
    );

    const handleChange = newValue => {
        const facIds = fp.pipe([
            fp.map(fp.get('attributes.pcc.facId')),
            fp.filter(fp.identity)
        ])(newValue);
        onChange(facIds);
    }

    return (
        <ChipList value={valueOptions} onChange={onChange && handleChange} options={facilities}
            addLabel="Add facility"
            getOptionLabel={fp.get('attributes.pcc.facilityName')}
            getOptionSelected={(o, value) => {
                const name = fp.get('attributes.pcc.facId', o);
                return !fp.includes(name, value);
            }}
        />
    );
}

export default function useUserFacilityColumn(field, props={}) {
    const lookup = useSelector(selectFacilityLookup);

    return tableColumn({
        title: 'Facilities',
        field,
        // field: 'data.attributes.userinfo.facility_ids',
        component: FacilityList,
        sorting: false,
        lookup,
        ...props
    });
}
