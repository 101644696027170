import React from 'react';

import InfoCard from '_app/components/InfoCard';
import {NotepadAvatar} from '_app/art/MedicalIcons';

export default function ProgressNotesCard(props) {
    return (
        <InfoCard
            color='blue'
            Avatar={NotepadAvatar}
            title='Progress Notes'
            {...props}
        ></InfoCard>
    );
}
