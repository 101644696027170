import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import UriTemplate from 'uritemplate';

import {Paper, Button, makeStyles} from '@material-ui/core';
import {ArrowBack} from '@material-ui/icons';

import {useApi, ducks} from '@arborian/narrf';

import {url_for} from '_app/routes';
import PatientPage from '_app/components/PatientPage';

import ConditionTable from '_app/components/ConditionTable';
import MedicationTable from '_app/components/MedicationTable';
import AllergyTable from '_app/components/AllergyTable';
import ImmunizationTable from '_app/components/ImmunizationTable';
import DiagnosticReportTable from '_app/components/DiagnosticReportTable';
import ProgressNotesTable from '_app/components/ProgressNotesTable';

const useStyles = makeStyles(theme => ({
    hform: {
        display: 'flex',
        justifyItems: 'center',
    },
    field: {
        margin: theme.spacing(0, 1),
    },
    paper: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1),
    },
}));

const LabsTable = props => (
    <DiagnosticReportTable
        title='Lab Reports'
        reportType='Laboratory'
        {...props}
    />
);
const RadiologyTable = props => (
    <DiagnosticReportTable
        title='Radiology'
        reportType='Radiology'
        {...props}
    />
);

const config = {
    conditions: {title: 'Health Conditions', component: ConditionTable},
    medications: {title: 'Medications', component: MedicationTable},
    allergies: {title: 'Allergies', component: AllergyTable},
    immunizations: {title: 'Immunizations', component: ImmunizationTable},
    labs: {title: 'Labs', component: LabsTable},
    radiology: {title: 'Radiology', component: RadiologyTable},
    progressNotes: {title: 'Progress Notes', component: ProgressNotesTable},
};

export default function InfoPage() {
    const api = useApi();
    const classes = useStyles();
    const {patientId, type} = useParams();
    const [init, setInit] = useState(false);
    const patient = useSelector(
        ducks.jsonapi.selectObject(['Patient', patientId]),
    );

    useEffect(() => {
        if (!init) {
            setInit(true);
            const url = UriTemplate.parse(
                api.directory.data.links['patient.patient'],
            ).expand({patientId});
            api.fetchJsonApi(url, {include: ['facility', 'photo']});
        }
    }, [api, patientId, init, setInit]);

    const Component = config[type].component;

    return (
        <PatientPage title={config[type].title} patient={patient}>
            <Paper className={classes.paper}>
                <Button
                    component={Link}
                    to={url_for('patientHome', {patientId: patientId})}
                    startIcon={<ArrowBack />}
                >
                    Return to Dashboard
                </Button>
                <Component patient={patient} />
            </Paper>
        </PatientPage>
    );
}
