import React from 'react';

import BasePage from '_app/components/BasePage';


export default function HomePage() {
    return (
        <BasePage title="Home">
        </BasePage>
    )
}
