import React from 'react';
import fp from 'lodash/fp';

import {Grid} from '@material-ui/core';

import ConditionsCard from '_app/components/ConditionsCard';
import MedicationsCard from '_app/components/MedicationsCard';
import AllergiesCard from '_app/components/AllergiesCard';
import ImmunizationsCard from '_app/components/ImmunizationsCard';
import LabsCard from '_app/components/LabsCard';
import RadiologyCard from '_app/components/RadiologyCard';
import ProgressNotesCard from '_app/components/ProgressNotesCard';

export default function InfoDashboard({patient}) {
    const cards = [
        {key: 'conditions', component: ConditionsCard},
        {key: 'medications', component: MedicationsCard},
        {key: 'allergies', component: AllergiesCard},
        {key: 'immunizations', component: ImmunizationsCard},
        {key: 'labs', component: LabsCard},
        {key: 'radiology', component: RadiologyCard},
        {key: 'progressNotes', component: ProgressNotesCard},
    ];

    return (
        <Grid container spacing={2}>
            {fp.map(
                v => (
                    <Grid key={v.key} item xs={12} md={6}>
                        <v.component patient={patient} type={v.key} />
                    </Grid>
                ),
                cards,
            )}
        </Grid>
    );
}
