import React from 'react';

import VitalCard from '_app/components/VitalCard';
import {HeartRateAvatar} from '_app/art/MedicalIcons';

export default function HeartRateCard({patient, pcc}) {
    if(!pcc) return null;
    return (
        <VitalCard color='green' Avatar={HeartRateAvatar} type="heartrate"
            patient={patient} {...pcc}
        >
            <h6>Heart rate</h6>
        </VitalCard>
    )
}

