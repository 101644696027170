import React, {useEffect, useState, useRef} from 'react';
import {createSelector} from '@reduxjs/toolkit';
import fp from 'lodash/fp';
import {useDispatch, useSelector} from 'react-redux';

import MaterialTable from 'material-table';
import {Chip} from '@material-ui/core';

import {useApi, ducks} from '@arborian/narrf';

import col from '_app/components/columns';


function IdentityList({user}) {
    const rel = useSelector(ducks.jsonapi.selectRelated(user));
    return (
        <>
            {fp.map(
                ident => <Chip key={ident.id} label={fp.get('attributes.plugin_id', ident)}/>,
                rel.identities,
            )}
        </>
    );
}


const selectOrgLookup = createSelector(
    ducks.jsonapi.selectObject('PCCFacility'), facilities => {
        return fp.pipe([
            fp.toPairs,
            fp.map(([id, fac]) => {
                const pcc = fp.get('attributes.pcc', fac);
                return [pcc.orgUuid, pcc.orgName];
            }),
            fp.uniq,
            fp.fromPairs
        ])(facilities);
    }
);


export default function AdminPatientTable() {
    const api = useApi();
    const dispatch = useDispatch();
    const [init, setInit] = useState(false);
    const tableRef = useRef();

    const facilityColumn = col.useUserFacility('data.attributes.userinfo.facility_ids');
    const roleColumn = col.useUserRole();

    useEffect(() => {
        if(!init) {
            setInit(true);
            api.fetchAllJsonApi(api.directory.data.links['pcc.facilities']);
            api.fetchAllJsonApi(api.directory.data.links['auth.roles']);
        }
    }, [api, init, setInit]);

    const orgLookup = useSelector(selectOrgLookup);

    const fetchData = async options => {
        const result = await api.fetchMaterialTable(
            api.directory.data.links['auth.subs'], {
                ...options,
                include: ['identities'],
            }
        );
        return result;
    }

    const refresh = () => tableRef.current.onQueryChange();

    const onRowDelete = async (rowData) => {
        api.fetch(rowData.data.links.self, {method: 'DELETE'});
        dispatch(ducks.jsonapi.deleteData(rowData.data));
        tableRef.current.onQueryChange();
    }

    const onRowUpdate = async (rowData, oldRowData) => {
        const {data} = rowData;
        await api.fetchJson(data.links.self, {
            method: "PATCH",
            json: {data}
        });
    }

    const columns = [
        {title: 'Email', field: 'data.attributes.userinfo.email', editable: false},
        {title: 'Name', field: 'data.attributes.userinfo.name', editable: false},
        {
            title: 'Org', field: 'data.attributes.userinfo.orgUuid', 
            sorting: false, editable: false, lookup: orgLookup
        },
        {title: 'Creds', render: row => <IdentityList user={row.data}/>, sorting: false},
        facilityColumn,
        roleColumn,
    ];

    return (
        <MaterialTable 
            title="User Administration"
            tableRef={tableRef}
            columns={columns}
            data={fetchData}
            editable={{onRowUpdate, onRowDelete}}
            options={{
                search: false,
                filtering: true,
            }}
            detailPanel={[
            ]}
            actions={[
               {
                  icon: 'refresh',
                  tooltip: 'Refresh table',
                  isFreeAction: true,
                  onClick: refresh
                },
            ]}
        />
    );
}
