import React, {useState, useMemo, useCallback, useEffect} from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';
import {Link, useHistory, useLocation, useParams} from 'react-router-dom';
import UriTemplate from 'uritemplate';
import {parseISO, subMonths} from 'date-fns';

import Chart from 'react-apexcharts';
import {Formik, Form, Field} from 'formik'
import {KeyboardDatePicker} from 'formik-material-ui-pickers';
import {Paper, Button, makeStyles} from '@material-ui/core';

import {useApi, ducks} from '@arborian/narrf';

import {url_for} from '_app/routes';
import {selectHistoricalObservations} from '_app/duck';
import PatientPage from '_app/components/PatientPage';

const charts = {
    painLevel: { 
        title: 'Pain Level',
        series: ['value'],
        options: {
            yaxis: {
                max: 10
            }
        },
    },
    temperature: {
        title: 'Body Temperature',
        series: ['value'],
    },
    heartrate: {
        title: 'Heart Rate',
        series: ['value'],
    },
    bloodPressure: {
        title: 'Blood Pressure',
        series: ['systolicValue', 'diastolicValue'],
    },
    bloodSugar: {
        title: 'Blood Sugar',
        series: ['value'],
    },
    oxygenSaturation: {
        title: 'Oxygen Saturation',
        series: ['value'],
    },
    respirations: {
        title: 'Respiration Rate',
        series: ['value'],
    },
    weight: {
        title: 'Weight',
        series: ['value'],
    },
    height: {
        title: 'Height',
        series: ['value'],
    },
}

const useStyles = makeStyles(theme => ({
    hform: {
        display: 'flex',
        justifyItems: 'center',
    },
    field: {
        margin: theme.spacing(0, 1)
    },
    paper: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1)
    },
    
}));

const DateRangeForm = ({onSubmit, initialValues}) => {
    const classes = useStyles();
    return (
        <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>{formikProps => (
            <Form onSubmit={formikProps.handleSubmit} className={classes.hform}>
                <Field className={classes.field} label="Start" format="MM/dd/yyyy" component={KeyboardDatePicker} name="startDate"/>
                &nbsp; &mdash; &nbsp;
                <Field className={classes.field} label="End" format="MM/dd/yyyy" component={KeyboardDatePicker} name="endDate"/>
                <Button className={classes.field} type="submit" variant="contained" color="primary">Change</Button>
            </Form>
        )}</Formik>
    );
}

const useDateRangeQuery = () => {
    const location = useLocation();
    return useMemo(() => {
        const q = new URLSearchParams(location.search);
        let startDate = q.get('startDate');
        let endDate = q.get('endDate');
        endDate = endDate ? parseISO(endDate) : new Date();
        startDate = startDate ? parseISO(startDate) : subMonths(endDate, 3);
        return {startDate, endDate};
    }, [location]);
}


export default function HistoryPage() {
    const api = useApi();
    const classes = useStyles();
    const {patientId, type} = useParams();
    const history = useHistory();
    const location = useLocation();
    const [init, setInit] = useState(false);
    const patient = useSelector(ducks.jsonapi.selectObject(['Patient', patientId]));
    const rel = useSelector(ducks.jsonapi.selectRelated(patient));
    const timeZone = fp.get('attributes.timeZone', rel.facility);

    const {startDate, endDate} = useDateRangeQuery();

    const chart = fp.get(type, charts);
    // eslint-disable-next-line
    const selectObservations = useCallback(
        selectHistoricalObservations(type, {startDate, endDate}, timeZone),
        [type, startDate, endDate, timeZone]);

    const obss = useSelector(selectObservations);

    useEffect(() => {
        if(!init) {
            setInit(true);
            const url = UriTemplate.parse(
                api.directory.data.links['patient.patient']
            ).expand({patientId});
            api.fetchJsonApi(url, {include: ['facility', 'photo']});
        }
    }, [api, patientId, init, setInit]);

    useEffect(() => {
        const url = UriTemplate.parse(
            api.directory.data.links['artifact.artifacts']
        ).expand({patientId, atype: 'observation'})

        api.fetchAllJsonApi(url, {filter: {
            'pcc.type': type,
            'pcc.recordedDate': JSON.stringify({
                $gte: {$date: startDate.getTime()}, 
                $lte: {$date: endDate.getTime()},
            })
        }})
    }, [api, patientId, timeZone, type, startDate, endDate]);

    const handleSetDateRange = async value => {
        console.log('Submit', value);
        let searchParams = new URLSearchParams(location.search);
        searchParams.set('startDate', value.startDate.toISOString());
        searchParams.set('endDate', value.endDate.toISOString());
        history.push({search: searchParams.toString()});
    }

    const series = fp.map(s => ({
        name: s,
        data: fp.map(o => ({
            x: fp.get('attributes.pcc.recordedDate', o),
            y: fp.get('attributes.pcc', o)[s]
        }), obss)
    }), chart.series);

    const initialValues = {
        startDate, endDate
    };

    return (
        <PatientPage title={`${chart.title} History`} patient={patient}>
            <Paper className={classes.paper}>
                <Button component={Link} to={url_for('patientHome', {patientId: patientId})}>
                    Return to Dashboard
                </Button>
                <DateRangeForm 
                    initialValues={initialValues}
                    onSubmit={handleSetDateRange}/>
                <Chart 
                    series={series} 
                    options={{
                        xaxis: { type: 'datetime' },
                        ...chart.options
                    }}
                    type="line" width="100%" height="400"
                />
            </Paper>
        </PatientPage>
    );
}
