import React from 'react';
import {useSelector} from 'react-redux';
import {Avatar} from '@material-ui/core';
import fp from 'lodash/fp';

import {ducks} from '@arborian/narrf';

export default function PatientAvatar({patient}) {
    const related = useSelector(ducks.jsonapi.selectRelated(patient));
    return (
        <Avatar src={fp.get('photo.links.content', related)}/>
    )
}

