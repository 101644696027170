import React from 'react';
import {makeStyles} from '@material-ui/core';

import AutoLogout from '_app/components/AutoLogout';

const useStyles = makeStyles(theme => ({
    root: {
        '& h1': {
            ...theme.typography.h1,
            margin: 0
        },
        '& h2': {
            ...theme.typography.h2,
            margin: 0
        },
        '& h3': {
            ...theme.typography.h3,
            margin: 0
        },
        '& h4': {
            ...theme.typography.h4,
            margin: 0
        },
        '& h5': {
            ...theme.typography.h5,
            margin: 0
        },
        '& h6': {
            ...theme.typography.h6,
            margin: 0
        },
        '& p': theme.typography.body1,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: 1,
    },
}));


export default function BasePage({title, subtitle, children}) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <AutoLogout />
            {children}
        </div>
    )
}
