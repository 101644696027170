import React from 'react';

import VitalCard from '_app/components/VitalCard';
import {ThermometerAvatar} from '_app/art/MedicalIcons';

export default function HeartRateCard({patient, pcc}) {
    return (
        <VitalCard color='green' Avatar={ThermometerAvatar} type="temperature" 
            patient={patient} {...pcc}
        >
            <h6>Body temperature</h6>
        </VitalCard>
    )
}
