import React from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {
    Grid, Divider,
    Card, CardHeader, CardContent, 
    makeStyles
} from '@material-ui/core';

import {ducks} from '@arborian/narrf';

import {humanName, humanAge} from '_app/util';
import PatientAvatar from '_app/components/PatientAvatar';
import TelephoneButton from '_app/components/TelephoneButton';
import EmailButton from '_app/components/EmailButton';
import AddressBlock from '_app/components/AddressBlock';
import PhonesBlock from '_app/components/PhonesBlock';

const useStyles = makeStyles(theme => ({
    card: {
        margin: theme.spacing(2, 0),
//        minWidth: theme.spacing(32),
        backgroundColor: theme.palette.cf.blue + '0c', 
        /*
        [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(2),
        },
        */
    },
    infoButton: {
        width: "100%",
    },
    truncated: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    divider: {
        margin: theme.spacing(1),
    }
}));


export default function PatientSummary({patient}) {
    const classes = useStyles();
    const rel = useSelector(ducks.jsonapi.selectRelated(patient));
    const name = humanName(patient.attributes.pcc);
    const age = humanAge(patient.attributes.pcc);
    const phone = fp.get('attributes.pcc.phone', patient);
    const email = fp.get('attributes.pcc.email', patient);
    return (
        <Card className={classes.card}>
            <CardHeader 
                avatar={<PatientAvatar patient={patient}/>}
                title={<h6>{name}</h6>}
                subheader={(<span>
                    Age: <strong>{age}</strong>&nbsp;
                    Gender: <strong>{fp.get('attributes.pcc.gender', patient)}</strong>
                    <br/>
                    Room: <strong>
                        {fp.get('attributes.pcc.unitDesc', patient)}
                        {fp.get('attributes.pcc.floorDesc', patient)} / {fp.get('attributes.pcc.roomDesc', patient)}
                    </strong>
                </span>)}
                /*
                action={
                    <IconButton aria-label="settings">
                        <I.MoreVert/>
                    </IconButton>
                }
                 */
            />
            <CardContent>
                <Grid container spacing={2} justify="center">
                    {phone && (
                        <Grid item xs={12} sm={5}>
                            <TelephoneButton variant="outlined" 
                                className={classes.infoButton}
                                value={phone}
                            />
                        </Grid>
                    )}
                    {email && (
                        <Grid item xs={12} sm={5}>
                            <EmailButton variant="outlined" 
                                className={classes.infoButton}
                                value={email}
                            />
                        </Grid>
                    )}
                </Grid>
                <Divider className={classes.divider}/>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12} sm={5}>
                        <AddressBlock name={fp.get('attributes.pcc.facilityName', rel.facility)} value={fp.get('attributes.pcc', rel.facility)}/>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <PhonesBlock value={fp.get('attributes.pcc', rel.facility)}/>
                    </Grid>
                </Grid>
            </CardContent>
            {/* IDK what else goes on the "full profile", so we'll skip this for now
            <CardActions >
                <Button fullWidth color="primary" variant="contained"
                    onClick={() => history.push(url_for('patientHome', {patientId: patient.id}))}
                >
                    See full profile
                </Button>
            </CardActions>
            */}
        </Card>
    );
}
