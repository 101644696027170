import React from 'react';

import VitalCard from '_app/components/VitalCard';
import {DropAvatar} from '_app/art/MedicalIcons';

export default function HeartRateCard({patient, pcc}) {
    if(!pcc) return null;
    const title = `${pcc.systolicValue} / ${pcc.diastolicValue} ${pcc.unit}`
    return (
        <VitalCard title={title} color='blue' Avatar={DropAvatar} type="bloodPressure" 
            patient={patient} {...pcc}
        >
            <h6>Blood pressure</h6>
        </VitalCard>
    )
}
