import React, {useState, useCallback} from 'react';


export const useAsyncError = () => {
    const [, setError] = useState();
    return useCallback(
        e => {
            setError(() => {
                throw e;
            });
        },
        [setError],
    );
};


export default class ErrorBoundary extends React.Component {
    state = { error: false };

    static getDerivedStateFromError(error) {
        return { error };
    }

    componentDidCatch(error) {
        try {
            this.props.onError && this.props.onError(error);
        } catch (error2) {
            console.error('Error calling onError', error, error2)
        }
    }

    render() {
        return this.state.error ? this.props.fallback() : this.props.children;
    }
}