import React from 'react';
import fp from 'lodash/fp';

export default function AddressBlock({name, value}) {
    if(!value) return null;
    let lines = [ name, value.addressLine1 ];
    if(value.addressLine2) {
        lines.push(value.addressLine2);
    }
    lines.push(`${value.city}, ${value.state} ${value.postalCode}`);
    return (
        <address>
            {fp.map(line => <React.Fragment key={line}>{line}<br/></React.Fragment>, lines)}
        </address>
    )
}
