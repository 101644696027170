import React, {useRef, useState, useEffect, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {Button, makeStyles} from '@material-ui/core';
import {Drafts, Markunread} from '@material-ui/icons';

import {useApi, ducks, components} from '@arborian/narrf';

import {MESSAGE_TYPES} from '_app/constants';
import {DateTime} from '_app/components/DateTime';
import MessageDetailPanel from '_app/components/MessageDetailPanel';

const {
    DataTable,
    Column,
    Action,
    DetailPanel,
    useLocationFetchOptions,
    updateLocationFetchOptions,
} = components;

const lookupMessageTypes = fp.pipe([
    fp.map(o => [o.value, o.label]),
    fp.fromPairs,
])(MESSAGE_TYPES);

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(0, 0, 2, 0),
        // minWidth: theme.spacing(32),
        '& .MuiPaper-root': {
            backgroundColor: theme.palette.cf.blue + '0c',
            padding: theme.spacing(2),
        },
        '& thead.MuiTableHead-root th': {
            backgroundColor: theme.palette.cf.blue + '0c',
        },
    },
    unread: {
        '& .MuiTableCell-root': {
            fontWeight: 'bolder',
        },
    },
    hidden: {display: 'none'},
}));

function MessageDetails({inboxMessageId}) {
    const api = useApi();
    const imsg = useSelector(
        ducks.jsonapi.selectObject(['InboxMessage', inboxMessageId]),
    );
    const rel = useSelector(ducks.jsonapi.selectRelated(imsg));
    useEffect(() => {
        if (!fp.get('attributes.read', imsg)) {
            api.fetchJson(imsg.links.self, {
                method: 'PATCH',
                json: {data: fp.set('attributes.read', true, imsg)},
            });
        }
    }, [imsg, api]);
    return <MessageDetailPanel message={rel.message} />;
}

function ReadStatus({row}) {
    const imsg = useSelector(
        ducks.jsonapi.selectObject(['InboxMessage', row.id]),
    );
    return fp.get('attributes.read', imsg) ? <Drafts /> : <Markunread />;
}

export default function PatientInbox({patient}) {
    const api = useApi();
    const classes = useStyles();
    const tableRef = useRef();
    const history = useHistory();
    const [showArchived, setShowArchived] = useState(false);

    const fetchData = useCallback(
        fetchOptions => {
            const url = fp.get('relationships.inbox.links.related', patient);
            return api.fetchDataTable(url, {
                include: ['message'],
                ...fetchOptions,
            });
        },
        [api, patient],
    );

    const refresh = () => {
        tableRef.current.fetch();
        //tableRefOld.current.onQueryChange();
    };

    useEffect(refresh, [showArchived]);

    const onBatchChange = newAttrs => async (ev, rows) => {
        let promises = fp.map(r => {
            let attributes = fp.merge(r.data.attributes, newAttrs);
            return api.fetch(r.data.links.self, {
                method: 'PATCH',
                json: {data: fp.set('attributes', attributes, r.data)},
            });
        }, rows);
        await Promise.all(promises);
        refresh();
    };

    const toggleArchived = () => {
        setShowArchived(value => !value);
    };

    const rowClass = row => {
        return null;
        /*
         * TODO: Restore this when we get updates from the store back to the list of rows
        if (!fp.get('data.attributes.read', row)) {
            return classes.unread;
        } else {
            return null;
        }
        */
    };

    const defaultFetchOptions = {
        sort: {field: 'attributes.timestamp', direction: 'desc'},
        filter: {'attributes.deleted': showArchived},
        page: {size: 5},
    };
    const fetchOptions = useLocationFetchOptions(defaultFetchOptions, 'msg.');
    const onChangeFetchOptions = useCallback(
        o => {
            const newParams = updateLocationFetchOptions(o, 'msg.');
            history.push('?' + newParams.toString());
        },
        [history],
    );

    return (
        <div className={classes.root}>
            <DataTable
                title={showArchived ? 'Archived messages' : 'Inbox'}
                tableRef={tableRef}
                data={fetchData}
                options={{
                    selection: true,
                    search: true,
                    rowClass,
                }}
                fetchOptions={fetchOptions}
                onChangeFetchOptions={onChangeFetchOptions}
            >
                <Column
                    filtering={false}
                    sorting={false}
                    component={ReadStatus}
                    //render={row => <ReadStatus row={row} />}
                />
                <Column
                    title='Type'
                    field='attributes.type'
                    lookup={lookupMessageTypes}
                />
                <Column title='Sender' field='attributes.from_' />
                <Column title='Subject' field='attributes.subject' />
                <Column
                    title='Sent'
                    field='attributes.timestamp'
                    render={row => (
                        <DateTime
                            value={fp.get('data.attributes.timestamp', row)}
                        />
                    )}
                />
                <DetailPanel
                    render={row => {
                        console.log('Detail', row);
                        return <MessageDetails inboxMessageId={row.id} />;
                    }}
                />
                <Action
                    free
                    onClick={refresh}
                    tooltip='Refresh'
                    icon='refresh'
                />
                <Action
                    icon='archive'
                    tooltip='Archive message(s)'
                    onClick={onBatchChange({deleted: true})}
                    hidden={showArchived}
                />
                <Action
                    icon='unarchive'
                    tooltip='Restore message(s)'
                    onClick={onBatchChange({deleted: false})}
                    hidden={!showArchived}
                />
                <Action
                    icon='markunread'
                    tooltip='Mark message(s) unread'
                    onClick={onBatchChange({read: false})}
                />
                <Action
                    icon='drafts'
                    tooltip='Mark message(s) read'
                    onClick={onBatchChange({read: true})}
                />
            </DataTable>
            {showArchived ? (
                <Button onClick={toggleArchived}>Return to inbox</Button>
            ) : (
                <Button onClick={toggleArchived}>Show archived messages</Button>
            )}
        </div>
    );
}
