import fp from 'lodash/fp';
import {parseISO, differenceInYears} from 'date-fns';
import {format} from 'date-fns-tz';

export function formatDate(value, timeZone) {
    if(!value) return null;
    const date = (value instanceof Date
        ? value
        : parseISO(value)
    );
    const formatted = format(date, 'yyyy-MM-dd', {timeZone});
    return formatted;
}

export function formatDateTime(value, timeZone) {
    if(!value) return null;
    const date = (value instanceof Date
        ? value
        : parseISO(value)
    );
    const formatted = format(date, 'yyyy-MM-dd HH:mm (zzz)', {timeZone});
    return formatted;
}

export function humanName(value) {
    if(!value) return null;
    let parts = [];
    if(value.preferredName) {
        parts.push(value.preferredName);
    } else {
        parts.push(value.firstName);
    }
    parts.push(value.lastName);
    let name = parts.join(' ');
    if(value.suffix) {
        name += ', ' + value.suffix;
    }
    return name;
}

export function humanAge(value) {
    if(!value || !value.birthDate) return 'unknown';
    const birthDate = parseISO(value.birthDate);
    return differenceInYears(new Date(), birthDate);
}

export const renderMedName = attributes => (fp.get('generic', attributes)
    ? `${attributes.description} (${attributes.generic})`
    : fp.get('description', attributes)
)

export const renderStrength = attributes => (fp.get('strengthUOM', attributes)
    ? `${attributes.strength} ${attributes.strengthUOM}`
    : fp.get('strength', attributes)
)

export const renderDose = attributes => (fp.get('doseUOM', attributes)
    ? `${attributes.dose} ${attributes.doseUOM}`
    : fp.get('dose', attributes)
)


// CREDIT TO mpen's answer at https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
/**
 * Format bytes as human-readable text.
 * 
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use 
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 * 
 * @return Formatted string.
 */
export function humanFileSize(bytes, si=false, dp=1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}
