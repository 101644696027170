import React from 'react';

import {Hidden, makeStyles} from '@material-ui/core';

import NavPage from '_app/components/NavPage';
import PatientFiles from '_app/components/PatientFiles';
import PatientSummary from '_app/components/PatientSummary';

const useStyles = makeStyles(theme => ({
    contentArea: {
        flex: 1,
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2),
        },
        padding: theme.spacing(1),
    },
    sidebar: {
        width: theme.constants.sidebarWidth,
    },
}));

export default function PatientPage({title, children, patient}) {
    const classes = useStyles();
    if (!patient) return null;
    return (
        <NavPage>
            <div className={classes.contentArea}>
                <Hidden lgUp>
                    <PatientSummary patient={patient} />
                </Hidden>
                {children}
                <Hidden lgUp>
                    <PatientFiles patient={patient} />
                </Hidden>
            </div>
            <Hidden mdDown>
                <div className={classes.sidebar}>
                    <PatientSummary patient={patient} />
                    <PatientFiles patient={patient} />
                </div>
            </Hidden>
        </NavPage>
    );
}
