import React, {useState} from 'react';
import fp from 'lodash/fp';
import * as yup from 'yup';
import {useHistory} from 'react-router-dom';

import {Link, Button, makeStyles} from '@material-ui/core';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';

import {useApi} from '@arborian/narrf';

import ForgotPasswordDialog from '_app/components/ForgotPasswordDialog';

const loginSchema = yup.object().shape({
    username: yup.string().email().lowercase().required(),
    password: yup.string().required(),
});

const useStyles = makeStyles(theme => ({
    root: {
        width: theme.spacing(40),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.cf.accent,
        border: '1px solid #D1D3D4',
        borderRadius: 10,
        boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.25)',
    },
    textField: {
        marginTop: theme.spacing(2),
        '& .MuiInputBase-root': {
            backgroundColor: 'white'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000',
        },
    }
}));

export default function PasswordLoginForm({title, invitation, next}) {
    const api = useApi();
    const history = useHistory();
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);
    const onSubmit = async (data, formikProps) => {
        try {
            await api.loginSrp(data.username.toLowerCase(), data.password);
            if(next) {
                history.push(next);
            }
        } catch(e) {
            console.log('Error logging in', formikProps);
            formikProps.setFieldError('password', 'Invalid login')
        }
    }
    const initialValues = {
        username: fp.getOr('', 'attributes.email', invitation),
        password: ''
    }
    return (
        <div className={classes.root}>
            <ForgotPasswordDialog open={dialogOpen} onClose={() => setDialogOpen(false)}/>
            <Formik 
                initialValues={initialValues} 
                enableReinitialize 
                onSubmit={onSubmit}
                validationSchema={loginSchema}
            >{formikProps => (
                <Form onSubmit={formikProps.handleSubmit}>
                    <h6>{title}</h6>
                    <Field component={TextField} fullWidth className={classes.textField} variant="outlined" 
                        name="username" label="Email"/>
                    <Field component={TextField} fullWidth className={classes.textField} variant="outlined" 
                        name="password" label="Password" type="password"/>
                    <p><Link onClick={() => setDialogOpen(true)}>Forgot password?</Link></p>
                    <Button type="submit" fullWidth variant="contained" color="primary">
                        Sign In
                    </Button>

                </Form>
            )}</Formik>
        </div>
    )
}
