import React from 'react';

import VitalCard from '_app/components/VitalCard';
import {HeightAvatar} from '_app/art/MedicalIcons';

export default function HeightCard({patient, pcc}) {
    return (
        <VitalCard color='blue' Avatar={HeightAvatar} type="height" patient={patient} {...pcc}>
            <h6>Height</h6>
        </VitalCard>
    )
}
