import React, {useMemo}from 'react';
import fp from 'lodash/fp';
import MaterialTable from 'material-table';
import UriTemplate from 'uritemplate';

import {useApi} from '@arborian/narrf';
import {renderStrength, renderDose} from '_app/util';

const medStatusLookup = {
    ACTIVE: "Active",
    PENDING: "Pending",
    DISCONTINUED: "Discontinued",
    COMPLETED: "Completed",
}

const columns = [
    {
        title: 'Status', field: 'data.attributes.pcc.status', lookup: medStatusLookup,
        defaultFilter: ["ACTIVE"],
    },
    {title: 'Name', field: 'data.attributes.pcc.description'},
    {title: 'Generic', field: 'data.attributes.pcc.generic'},
    {title: 'Strength', render: med => renderStrength(med.data.attributes.pcc) },
    {title: 'Directions', field: 'data.attributes.pcc.directions'},
    {
        title: 'Start Date', field: 'data.attributes.pcc.startDate', type: 'date',
        filtering: false,
    },
]

function ScheduleDetails({data}) {
    const tableData = useMemo(
        () => fp.map(data => ({data}), data), 
        [data]
    );
    return <MaterialTable title="Schedule(s)"
        options={{search: false, paging: false}}

        columns={[
            {title: 'Dose', render: d => renderDose(d.data)},
            {title: 'Frequency', field: 'data.frequency'},
            {title: 'Indications', field: 'data.indicationsForUse'},
            {title: 'Schedule Type', field: 'data.scheduleType'},
        ]}
        data={tableData}
    />
}


export default function MedicationTable({patient, ...props}) {
    const api = useApi();
    if(!patient) return null;
    const fetch = async o => {
        const url = UriTemplate.parse(
            api.directory.data.links['artifact.artifacts']
        ).expand({patientId: patient.id, atype: 'medication'});
        let resp = await api.fetchMaterialTable(url, o);
        return resp;
    }
    return (
        <MaterialTable 
            title="Medications"
            data={fetch}
            columns={columns}
            options={{
                filtering: true
            }}
            detailPanel={
                med => <ScheduleDetails data={med.data.attributes.pcc.schedules}/>
            }
            {...props}
        />
    );
}
