import React from 'react';

import {
    Dialog, DialogActions, DialogContent, DialogContentText,
    Button,
} from '@material-ui/core';

export default function ConfirmationDialog({
    message, 
    confirmMessage='Confirm',
    onConfirm,
    open, onClose
}) {
    const handleConfirm = () => {
        onConfirm && onConfirm();
        onClose();
    }
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={handleConfirm}>{confirmMessage}</Button>
            </DialogActions>
        </Dialog>
    );
}
