import React, {useState, useEffect} from 'react';
import fp from 'lodash/fp';

import {useTableManager} from './Provider';
import {
    SelectLookupEditor as LookupEditor,
    DialogLookupFilter as LookupFilter,
    default as renderLookup,
} from './types/Lookup';
import {
    StringEditor,
    StringFilter,
    default as renderString,
} from './types/String';
import {DateEditor, DateFilter, default as renderDate} from './types/Date';
import {
    BooleanEditor,
    BooleanFilter,
    default as renderBoolean,
} from './types/Boolean';

function NoFilter({column}) {
    return <div>No filter for {column.type}</div>;
}

function renderDefault(row, column) {
    let value = column.field ? fp.get(column.field, row.data) : null;
    return value;
}

export default function DTableColumn({
    id,
    children,
    title,
    field,
    render,
    lookup,
    component,
    EditComponent,
    FilterComponent,
    filterProps,
    type = 'string',
    filtering = true,
    sorting = true,
    ...extra
}) {
    const tm = useTableManager();
    const [_id] = useState(() => Math.random().toString());
    if (!id) id = _id;

    if (!FilterComponent) {
        if (lookup) {
            FilterComponent = LookupFilter;
        } else if (type === 'string') {
            FilterComponent = StringFilter;
        } else if (type === 'date') {
            FilterComponent = DateFilter;
        } else if (type === 'boolean') {
            FilterComponent = BooleanFilter;
        } else {
            FilterComponent = NoFilter;
        }
    }
    if (!EditComponent) {
        if (lookup) {
            EditComponent = LookupEditor;
        } else if (type === 'string') {
            EditComponent = StringEditor;
        } else if (type === 'date') {
            EditComponent = DateEditor;
        } else if (type === 'boolean') {
            EditComponet = BooleanEditor;
        } else {
            EditComponent = null;
        }
    }
    if (!render) {
        if (lookup) {
            render = renderLookup;
        } else if (type === 'string') {
            render = renderString;
        } else if (type === 'date') {
            render = renderDate;
        } else if (type === 'boolean') {
            render = renderBoolean;
        } else {
            render = renderDefault;
        }
    }

    useEffect(() => {
        tm.setColumn(id, {
            id,
            title,
            field,
            render,
            lookup,
            component,
            EditComponent,
            FilterComponent,
            filterProps,
            type,
            filtering,
            sorting,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        id,
        title,
        field,
        render,
        lookup,
        component,
        EditComponent,
        FilterComponent,
        filterProps,
        type,
        filtering,
        sorting,
    ]);
    return null;
}
