import React from 'react';
import * as yup from 'yup';
import {useHistory} from 'react-router-dom';
import {Formik, Form, Field} from 'formik'
import {useSnackbar} from 'notistack';

import {Button, makeStyles} from '@material-ui/core';
import {TextField} from 'formik-material-ui';

import {useApi} from '@arborian/narrf';

import {url_for} from '_app/routes';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: theme.spacing(80),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.cf.accent,
        border: '1px solid #D1D3D4',
        borderRadius: 10,
        boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.25)',
    },
    textField: {
        margin: theme.spacing(1, 0),
        '& .MuiInputBase-root': {
            backgroundColor: 'white'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000',
        },
    }
}));

const Schema = yup.object().shape({
    password: yup.string().min(6).required(),
    password2: yup.string().oneOf([yup.ref('password'), null], "Passwords don't match"),
});


export default function PasswordResetForm({title, tok}) {
    const api = useApi();
    const history = useHistory();
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const onSubmit = async (values, formikProps) => {
        console.log('Submit', values);
        try {
            await api.fetchJson(api.directory.data.links['auth.passwordReset'], {
                method: 'POST',
                json: {data: {type: 'PasswordReset', attributes: {
                    password: values.password,
                    tok
                }}}
            });
            enqueueSnackbar('Password reset successfully. Please login.', {
                persist: false,
                variant: 'success',
            });

            history.push(url_for('home'))
        } catch(e) {
            console.error('Error resetting password', {title, tok})
            enqueueSnackbar(`
                Password could not be reset with the link provided. 
                It might be expired, or might have already been used.
                You can request a new link with the "Forgot Password" link above.
                `, {persist: true, variant: 'error'}
            )
            history.push(url_for('home'))
        }
    }
    const initialValues = {
        password: '',
        password2: '',
        tok,
    }
    return (
        <div className={classes.root}>
            <Formik 
                initialValues={initialValues} 
                enableReinitialize 
                onSubmit={onSubmit}
                validationSchema={Schema}
            >{formikProps => (
                <Form onSubmit={formikProps.handleSubmit}>
                    <h6>{title}</h6>
                    <Field fullWidth className={classes.textField} variant="outlined" component={TextField}
                        type="password" name="password" label="Password"/>
                    <Field fullWidth className={classes.textField} variant="outlined" component={TextField}
                        type="password" name="password2" label="Password (again)"/>
                    <Button type="submit" fullWidth variant="contained" color="primary">
                        Reset password
                    </Button>
                </Form>
            )}</Formik>
        </div>
    )
}
