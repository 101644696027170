import React from 'react';

import GaugeChart from 'react-gauge-chart'

import {FlashAvatar} from '_app/art/MedicalIcons';
import VitalCard from '_app/components/VitalCard';

export default function PainCard({patient, pcc}) {
    const title = pcc ? `${pcc.value} / 10` : '? / 10';
    const percent = pcc ? pcc.value / 10 : 0;
    return (
        <VitalCard color='red' Avatar={FlashAvatar} title={title} type="pain" patient={patient} {...pcc}>
            <h6>Pain level</h6>
            <div>
                <GaugeChart hideText id="painGauge" percent={percent}/>
            </div>
        </VitalCard>
    );
}
