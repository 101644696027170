import React from 'react'
import ReactMarkdown from 'react-markdown'

import {Paper, makeStyles} from '@material-ui/core'

import BasePage from '_app/components/BasePage';

const useStyles = makeStyles(theme => ({
  paper: {
    margin: 20,
    padding: 20,
    '& h1': theme.typography.h4,
    '& h2': theme.typography.h5,
    '& h3': theme.typography.h6,
    '& p': theme.typography.body1,
    '& li': theme.typography.body2,
  }
}));

export default function MarkdownPage({title, markdown}) {
  const classes = useStyles();
  return (
    <BasePage title={title}>
      <Paper className={classes.paper}>
        <ReactMarkdown source={markdown}/>
      </Paper>
    </BasePage>
  )
}
