import React from 'react';
import fp from 'lodash/fp';

const DialogContext = React.createContext();

export function useDialog(name) {
    return React.useContext(DialogContext)[name].current;
}

export default function DialogProvider({children, dialogs}) {
    const items = fp.pipe([
        fp.toPairs,
        fp.map(([name, Component]) => {
            let item = {
                name,
                ref: {current: null},
            };
            item.element = <Component key={name} dialogRef={item.ref} />;
            return item;
        }),
    ])(dialogs);

    const contextValue = fp.pipe([
        fp.map(item => [item.name, item.ref]),
        fp.fromPairs,
    ])(items);

    return (
        <DialogContext.Provider value={contextValue}>
            {fp.map('element', items)}
            {children}
        </DialogContext.Provider>
    );
}
