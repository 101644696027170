import React, {Fragment, useEffect, useState} from 'react';
import fp from 'lodash/fp';
import clsx from 'clsx';

import {
    Button,
    Menu,
    MenuItem,
    InputAdornment,
    IconButton,
    makeStyles,
} from '@material-ui/core';
import {ArrowDropDown} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    filterValue: {
        minWidth: theme.spacing(16),
    },
}));

function BooleanSelect({
    value,
    onChange,
    trueLabel = 'True',
    falseLabel = 'False',
    undefinedLabel = '',
}) {
    const [anchorEl, setAnchorEl] = useState();
    const handleClick = value => {
        setAnchorEl();
        console.log('Set filter to', value);
        onChange(value);
    };
    return (
        <>
            <Button
                onClick={ev => setAnchorEl(ev.currentTarget)}
                endIcon={<ArrowDropDown />}
            >
                {value === true
                    ? trueLabel
                    : value === false
                    ? falseLabel
                    : undefinedLabel}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={() => handleClick()}>
                    {undefinedLabel || <span>&nbsp;</span>}
                </MenuItem>
                <MenuItem onClick={() => handleClick(true)}>
                    {trueLabel}
                </MenuItem>
                <MenuItem onClick={() => handleClick(false)}>
                    {falseLabel}
                </MenuItem>
            </Menu>
        </>
    );
}

export default function BooleanFilter({
    column,
    filter,
    onChangeFilter,
    ...props
}) {
    const value = fp.get(column.field, filter);
    const handleChange = newValue => {
        if (newValue !== value) {
            onChangeFilter(column.field, newValue);
        }
    };
    return <BooleanSelect value={value} onChange={handleChange} {...props} />;
}
