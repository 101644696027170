import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {Grid} from '@material-ui/core';

import {selectLatestObservations} from '_app/duck';

import PainCard from '_app/components/PainCard';
import TemperatureCard from '_app/components/TemperatureCard';
import HeartRateCard from '_app/components/HeartRateCard';
import BloodPressureCard from '_app/components/BloodPressureCard';
import BloodSugarCard from '_app/components/BloodSugarCard';
import OxygenSaturationCard from '_app/components/OxygenSaturationCard';
import WeightCard from '_app/components/WeightCard';
import HeightCard from '_app/components/HeightCard';
import RespirationCard from '_app/components/RespirationCard';

const vitals = [
    {key: 'temperature', component: TemperatureCard},
    {key: 'heartrate', component: HeartRateCard},
    {key: 'bloodPressure', component: BloodPressureCard}, 
    {key: 'bloodSugar', component: BloodSugarCard}, 
    {key: 'oxygenSaturation', component: OxygenSaturationCard}, 
    {key: 'respirations', component: RespirationCard}, 
    {key: 'weight', component: WeightCard}, 
    {key: 'height', component: HeightCard}, 
];

export default function VitalsDashboard({patient}) {
    const obss = useSelector(selectLatestObservations);
    const pccVitals = useMemo(() => fp.pipe([
        fp.map(v => ({
            key: v.key,
            component: v.component,
            pcc: fp.get('attributes.pcc', obss[v.key])
        })),
        fp.filter('pcc')
    ])(vitals), [obss]);
    const painPcc = fp.get('painLevel.attributes.pcc', obss);
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
                <PainCard patient={patient} pcc={painPcc}/>
            </Grid>
            <Grid item container spacing={2} sm={6} md={8}>
                {fp.map(v => (
                    <Grid key={v.key} item xs={12} md={6}>
                        <v.component patient={patient} pcc={v.pcc}/>
                    </Grid>
                ), pccVitals)}
            </Grid>
        </Grid>
    );

}
