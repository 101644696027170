import React, {useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {Link, makeStyles, useTheme, useMediaQuery} from '@material-ui/core';
import MaterialTable from 'material-table';

import {useApi, ducks} from '@arborian/narrf';

import {humanFileSize} from '_app/util';
import {DateTime} from '_app/components/DateTime';

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2, 0),
        // minWidth: theme.spacing(32),
        '& .MuiPaper-root': {
            backgroundColor: theme.palette.cf.blue + '0c',
            padding: theme.spacing(2),
        },
        '& thead.MuiTableHead-root th': {
            backgroundColor: theme.palette.cf.blue + '0c',
        },
    },
    hidden: {display: 'none'},
}));

function Filename({file, blobs}) {
    const blob = blobs[fp.get('relationships.file.data.id', file)];
    return (
        <Link href={fp.get('links.content', blob)}>
            {fp.get('attributes.filename', blob)}
        </Link>
    );
}

function Filesize({file, blobs}) {
    const blob = blobs[fp.get('relationships.file.data.id', file)];
    return <>{humanFileSize(fp.get('attributes.length', blob))}</>;
}

function Filedate({file, blobs}) {
    const blob = blobs[fp.get('relationships.file.data.id', file)];
    return <DateTime value={fp.get('attributes.uploadDate', blob)} />;
}

export default function PatientFiles({patient}) {
    const api = useApi();
    const dispatch = useDispatch();
    const blobs = useSelector(ducks.jsonapi.selectObject('Blob'));
    const classes = useStyles();
    const inputRef = useRef();
    const tableRef = useRef();
    const url = fp.get('relationships.files.links.related', patient);

    const theme = useTheme();
    const narrow = useMediaQuery(theme.breakpoints.down('xs'));

    const handleAdd = async ev => {
        let file = ev.currentTarget.files[0];
        let body = new FormData();
        body.append('file', file);
        await api.fetchJsonApi(url, {method: 'POST', include: ['file'], body});
        inputRef.current.value = null;
        refresh();
    };

    const onRowDelete = async rowData => {
        api.fetch(rowData.data.links.self, {method: 'DELETE'});
        dispatch(ducks.jsonapi.deleteData(rowData.data));
        tableRef.current.onQueryChange();
    };

    const fetchData = async o => {
        let resp = await api.fetchMaterialTable(url, {
            include: ['file'],
            ...o,
        });
        fp.forEach(row => {
            const blob_id = fp.get('data.relationships.file.data.id', row);
            row.blob = fp.get(blob_id, blobs);
        }, resp.data);
        return resp;
    };

    const refresh = () => tableRef.current.onQueryChange();

    const allColumns = [
        {
            title: 'Filename',
            field: 'blob.attributes.filename',
            render: row => <Filename file={row.data} blobs={blobs} />,
        },
        {
            title: 'Size',
            field: 'blob.attributes.length',
            render: row => <Filesize file={row.data} blobs={blobs} />,
        },
        {
            title: 'Date',
            field: 'blob.attributes.uploadDate',
            type: 'datetime',
            render: row => <Filedate file={row.data} blobs={blobs} />,
        },
    ];

    const xsColumns = [
        {
            title: 'Filename',
            field: 'blob.attributes.filename',
            render: row => <Filename file={row.data} blobs={blobs} />,
        },
        {
            title: 'Size',
            field: 'blob.attributes.length',
            render: row => <Filesize file={row.data} blobs={blobs} />,
        },
    ];

    return (
        <div className={classes.root}>
            <input
                type='file'
                ref={inputRef}
                onChange={handleAdd}
                className={classes.hidden}
            />
            <MaterialTable
                title='Uploaded Files'
                tableRef={tableRef}
                options={{
                    search: false,
                    padding: 'dense',
                }}
                editable={{onRowDelete}}
                columns={narrow ? xsColumns : allColumns}
                data={fetchData}
                actions={[
                    {
                        isFreeAction: true,
                        icon: 'refresh',
                        tooltip: 'Refresh listing',
                        onClick: refresh,
                    },
                    {
                        isFreeAction: true,
                        icon: 'cloud_upload',
                        tooltip: 'Upload a file',
                        onClick: () => inputRef.current.click(),
                    },
                ]}
            />
        </div>
    );
}
