import React from 'react';

import VitalCard from '_app/components/VitalCard';
import {WeightAvatar} from '_app/art/MedicalIcons';

export default function WeightCard({patient, pcc}) {
    return (
        <VitalCard color='blue' Avatar={WeightAvatar} type="weight" patient={patient} {...pcc}>
            <h6>Weight</h6>
        </VitalCard>
    )
}
