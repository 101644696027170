const tos = `
# Carefolio, Inc | Terms of Service

_Last Updated Date: June 15, 2019_

Carefolio, Inc. ("**Carefolio**" or “**we**”) provides an online service to help organize, store, and manage information related to your care or the care of your children, parents, and loved ones (the “**Carefolio Services**”) through our website, accessible at Carefol.io, or via applications for the iOS or Android operating systems (collectively, the “**Site**”). Please read carefully the following Terms of Service (“**Terms**”) and our Privacy Policy, which is incorporated by reference into these Terms. These Terms govern your access to and use of the Services and Collective Content (defined below) via the Site, and constitute a binding legal agreement between you and Carefolio.

You acknowledge and agree that, by accessing or using the Carefolio Services, or by downloading, uploading, or posting any content from or on the Site or through the Carefolio Services, you are indicating that you have read, and that you understand and agree to be bound by, these Terms, whether or not you have registered via the Site. If you do not agree to these Terms, then you have no right to access or use the Site or Carefolio Services.

# NO MEDICAL ADVICE

## IF YOU ARE HAVING A MEDICAL EMERGENCY, DIAL 911 IMMEDIATELY.

We do not provide any medical or pharmaceutical advice. We are not health care providers. As a result, the Carefolio Services are not intended to provide medical or pharmaceutical information or replace the advice of a healthcare professional. The Carefolio Services are solely designed to support, not replace, the relationship that exists with any physician, pharmacist and other healthcare professionals. What we do is provide a way for you to take control and manage health care data. While we are not a covered entity under the Health Insurance Portability and Accountability Act (HIPAA), we take both the protection of your private health information and your ability to control and access such information very seriously, and will only use and disclose your information in accordance with our Privacy Policy.

# PROFILES

When you register with us for access to the Carefolio Services and successfully complete the account registration process you will become a “Member” of the Carefolio Service. As a Member, you will have the opportunity to create one or more care profiles (“Profiles”) and to add information, data, and images to your Profile(s) (“Profile Content”). A Profile may relate to you or it may relate to your child, parent, or other person you are caring for. As a Member, you will have the opportunity to invite third-party caregivers (“Caregiver”) to access the Profiles that you create. If you elect to invite a Caregiver to access your Profile(s), that Caregiver will receive an invitation to register with the Carefolio Services and will need to complete the account registration process to become a Member. In addition, any Caregiver that you invite to access your Profile(s) will have full access to all Profile Content within your Profile(s) as well as the ability to add, delete, distribute, download, and edit the same. Because of the sensitive and private nature of your health care and the health care of those loved ones on whose behalf you may be creating a Profile, we recommend that you protect the private and sensitive information contained within your Profile(s) and consider carefully whether to permit any Caregiver to access your Profiles. All Members are responsible for monitoring the Profile Content uploaded or added to their Profiles, whether uploaded by the Member, by a Caregiver, or by other third parties.

If you are creating a profile on behalf of another person, by accepting these terms you attest that you are authorized by that person to create a profile on their behalf. To the extent you take action on behalf of the person you are caring for, you also attest that you have valid legal authority to take that action on their behalf.

Please note that you, and not Carefolio, are responsible for maintaining and protecting all Profile Content. Carefolio will not be liable to you for any loss or corruption of your Profile Content, or for any costs or expenses associated with backing up or restoring any of your Profile Content.

# CARE ADVISORS

We have a team of dedicated Care Advisors whose job is to support you in whatever way they can. By registering with us to access Carefolio Services, you agree that Carefolio and Carefolio’s Care Advisors may contact you to discuss Carefolio’s services as well as third party services Carefolio may make available to you in connection with your health and wellness. Third party services may include offering health insurance plans, including Medicare Advantage or Medicare Part D health plan options that may be available through licensed insurance brokers, or other services that we may have the opportunity to offer from time to time. You agree that Carefolio may contact you about Carefolio Services or third party services in various ways including, but not limited to, email, Inbox in the app, direct mail, telephone, cell phone, and SMS/text messages.

# ADDITIONAL TERMS

Certain areas of the Site (and your access to or use of certain aspects of the Carefolio Services) may have different terms and conditions posted or may require you to agree with and accept additional terms and conditions in order to obtain access to or use of that Site area or Carefolio Service. Any additional terms will be made available to you at the time you access that applicable Site area or Carefolio Service. If there is a conflict between these Terms and terms and conditions posted for a specific area of the Site or particular Carefolio Services, the latter terms and conditions will take precedence with respect to your use of or access to that area of the Site or particular Carefolio Service, as applicable.

# MODIFICATION

Carefolio reserves the right, at its sole discretion, to modify, discontinue, or terminate the Carefolio Services or to modify these Terms, at any time and without prior notice. If we modify these Terms, we will post the modified Terms and alert you to the modified Terms on the Site or otherwise provide you with notice of the modification. We will also update the “Last Updated Date” at the top of these Terms. By continuing to access or use the Carefolio Services after we have posted a modification on the Site or have provided you with notice of a modification, you are indicating that you agree to be bound by the modified Terms. If the modified Terms are not acceptable to you, your only recourse is to cease using the Site and Carefolio Services.

# ELIGIBILITY

The Site and Carefolio Services are intended solely for persons who are 13 or older. Any access to or use of the Carefolio Services by anyone under 13 is expressly prohibited. By accessing or using the Carefolio Services you represent and warrant that you are 13 or older.

# ACCOUNT REGISTRATION

In order to access the Carefolio Services, including to (i) create and manage a Profile and (ii) post any Profile Content through the Carefolio Services, you must register to create an account (“Account”) and become a Member. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. Carefolio reserves the right to suspend or terminate your Account if any information provided during the registration process or thereafter proves to be inaccurate, not current, or incomplete. You will be asked to create a password when you create your Account. Carefolio does not have access to your passwords and if you forget your password you will be asked to create a new one. You are responsible for safeguarding your password. You agree that you will not disclose your password to any third party and that you will take sole responsibility for any activities or actions under your Account, whether or not you have authorized such activities or actions. You will immediately notify Carefolio if you become aware of any unauthorized use of your Account.

# PRIVACY

See Carefolio’s Privacy Policy for information and notices concerning Carefolio’s collection and use of your personal information and the Profile Content you submit.

# OWNERSHIP

The Site, Carefolio Services, and the news and other content provided by Carefolio through the same, including the trademarks, trade dress and copyrightable text (collectively, the “Carefolio Content”), are protected by copyright, trademark, and other laws of the United States and foreign countries and are the proprietary designations of Carefolio. Any other trademarks, service marks, logos, trade names and any other proprietary designations are the trademarks or registered trademarks of their respective parties. You acknowledge and agree that the Site, Carefolio Services, and Carefolio Content, including all associated intellectual property rights, are the exclusive property of Carefolio and its licensors. You will not remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Site, Carefolio Services, or Carefolio Content.

As between any Member and Carefolio, all Profile Content is and remains the sole and exclusive property of you as the Member. Carefolio does not own any Profile Content and its use of the same is subject to these Terms and our Privacy Policy.

# LICENSE GRANTED BY MEMBER

By making available any Profile Content on or through the Carefolio Services, you hereby grant to Carefolio a worldwide, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid up right and license to access, use, view, copy, adapt, reformat, and download, such Profile Content only on, through or by means of the Carefolio Services and for the limited purpose of making such Profile Content available to you and any Caregivers with whom you choose to share access to one or more of your Profiles via the Carefolio Services and as otherwise set forth in our Privacy Policy. In the event you do elect to make one or more Profile(s) and their corresponding Profile Content available to one or more Caregivers, you further grant Carefolio a right to sublicense the Profile Content to such Caregiver to allow access to and use of the same as permitted by the Carefolio Services. ALTHOUGH ANY CAREGIVER IS SUBJECT TO THESE TERMS AS A MEMBER, WE CANNOT CONTROL ANY USE OF PROFILE CONTENT BY ANY CAREGIVER AND YOU ARE SOLELY RESPONSIBLE FOR THE SAME AND ANY LIABILITY FOR SUCH ACCESS.

You acknowledge and agree that you are solely responsible for all Profile Content that you make available through the Carefolio Services. Accordingly, you represent and warrant that: (i) you either are the sole and exclusive owner of all Profile Content that you make available through the Carefolio Services or you have all rights, licenses, consents and releases from your loved one that are necessary to grant to Carefolio the rights in such Profile Content, as contemplated under these Terms, including the provision to Carefolio of Personal Information (as defined in the Carefolio Privacy Policy) submitted to us as part of a Profile; and (ii) neither the Profile Content nor your posting, uploading, publication, submission, or transmittal of the Profile Content or Carefolio’s use of the Profile Content (or any portion thereof) on, through or by means of the Carefolio Services will infringe, misappropriate, or violate a third party’s patent, copyright, trademark, trade secret, moral rights, or other intellectual property rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation.

# LICENSES GRANTED BY Carefolio TO Carefolio CONTENT AND MEMBER CONTENT

Subject to your compliance with the terms and conditions of these Terms, Carefolio grants you a limited, non-exclusive, non-transferable license to view, download, and print any Carefolio Content solely for your personal and non-commercial purposes. You will not use, copy, adapt, modify, prepare derivative works based upon, distribute, download, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Site, Carefolio Services or Carefolio Content, except as expressly permitted in these Terms. No licenses or rights are granted to you by implication or otherwise under any intellectual property rights owned or controlled by Carefolio or its licensors, except for the licenses and rights expressly granted in these Terms. Any misuse of the Site, Carefolio Services or Carefolio Content available via the same may be pursued by Carefolio to the fullest extent permitted by law.

In addition, if you are invited as a Caregiver to receive access to one or more Profile(s) by another Member(s) subject to your compliance with the terms and conditions of these Terms, Carefolio grants you a limited, non-exclusive, non-transferable license to access and use the Profile Content to which you are granted access by another Member. You have no right to sublicense the license rights granted in this section. Unless otherwise agreed between you and the Member granting you access to such Profile Content, all Profile Content is deemed to be confidential and you may not publicly disclose, display, distribute, transmit, stream, broadcast or otherwise use or exploit any Profile Content for any purpose other than the lawful provision and assistance of the management of the personal health care of the individual to whom the Profile Content relates.

# OTHER SERVICES

From time to time, we may present you with offers or promotions we believe to be helpful or relevant, which may be from us at Carefolio or they may be from third parties. Certain of these offers may require you to share or enter additional information about you or your loved one. You are responsible for managing the information you choose to share. Carefolio is providing the platform through which these services can be coordinated and managed, and although we do our best to select the highest quality third party partners and providers, we are not responsible for the choices you make to share your private information, or the accuracy or quality of such third party services. In that instance the Carefolio Services are only a venue for you to access those third party services and Carefolio is not a party to, nor are we otherwise involved in any transactions entered into via or facilitated by the Carefolio Services. Furthermore, Carefolio is not liable or responsible for any dispute that arises between you and any third party.

In addition, Carefolio will do its best to enable you to use the Carefolio Service as a singular console through which these third party services may be managed, including facilitating the delivery and receipt of communications between you and your healthcare providers, from pharmacies to physician’s offices. We cannot guarantee that we can or will be able to maintain our relationship with any particular third party. We also are not responsible for any of the service levels, availability, or quality of any such connections with third parties, and disclaim any responsibility for errors or miscommunication with the same. To the extent providing other services requires actions on your behalf, you consent to appointing Carefolio as your agent as reasonably needed to facilitate those services.

# FEEDBACK

We welcome and encourage you to provide feedback, comments, and suggestions for improvements to the Carefolio Services (“Feedback”). You may submit Feedback by emailing us at <support@carefol.io>, or via the Help and Feedback link on the Site. You acknowledge and agree that if you submit any Feedback to us, you hereby grant to us a non-exclusive, worldwide, perpetual, irrevocable, fully-paid, royalty-free, sub-licensable, and transferable license under any and all intellectual property rights that you own or control to use, copy, modify, create derivative works based upon, and otherwise exploit the Feedback for any purpose without any compensation to you of any kind.

# GENERAL PROHIBITIONS

You agree not to do any of the following:

- Impersonate or misrepresent your affiliation with any person or entity;
- Violate any applicable law or regulation;
- Collect or store any personally identifiable information, including any Profile Content and/or Personal Information (as defined in the Privacy Policy) from the Carefolio Services from other Members of the Carefolio Services without their express permission;
- Post, upload, publish, submit, or transmit any Profile Content that: (i) infringes, misappropriates, or violates a third party’s privacy or any rights of publicity, or any patent, copyright, trademark, trade secret, moral rights, or other intellectual property rights; (ii) violates, or encourages any conduct that would violate, any applicable law or regulation or would give rise to civil liability; (iii) is fraudulent, false, misleading, or deceptive; (iv) is defamatory, obscene, pornographic, vulgar, or offensive; (v) promotes discrimination, bigotry, racism, hatred, harassment, or harm against any individual or group; (vi) is violent or threatening or promotes violence or actions that are threatening to any person or entity; or (vii) promotes illegal or harmful activities or substances;
- Use, display, mirror, or frame the Site, or any individual element within the Carefolio Services or Carefolio Content, or Carefolio’s name, any Carefolio trademark, logo, or other proprietary information, or the layout and design of any page or form contained on a page, without Carefolio’s express written consent;
- Access, tamper with, or use restricted areas of the Site, Carefolio’s computer systems, or the technical delivery systems of Carefolio’s providers;
- Attempt to probe, scan, or test the vulnerability of any Carefolio system or network or breach any security or authentication measures;
- Avoid, bypass, remove, deactivate, impair, descramble, or otherwise circumvent any technological measure implemented by Carefolio or any of Carefolio’s providers or any other third party (including another user) to protect the Site, Carefolio Services, Carefolio Content or Profile Content;
- Attempt to access or search the Site, Carefolio Services, or Carefolio Content or download Carefolio Content or Profile Content from the Carefolio Services through the use of any engine, software, tool, agent, device, or mechanism (including spiders, robots, crawlers, data mining tools or the like) other than the software and/or search agents provided by Carefolio or other generally available third party web browsers;
- Use the Carefolio Services or Site to send any unsolicited or unauthorized advertising, promotional materials, email, junk mail, spam, chain letters, or other form of solicitation;
- Use any meta tags or other hidden text or metadata utilizing a Carefolio trademark, logo URL, or product name without Carefolio’s express written consent;
- Use the Site, Carefolio Services, Carefolio Content or Profile Content for any commercial purpose or the benefit of any third party or in any manner not permitted by these Terms;
- Forge any TCP/IP packet header or any part of the header information in any email or newsgroup posting, or in any way use the Site or Carefolio Services to send altered, deceptive or false source-identifying information;
- Attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Site or Carefolio Services;
Interfere with, or attempt to interfere with, the access of any user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Site or Carefolio Services; or
- Encourage or enable any other individual to do any of the foregoing.

Carefolio will have the right to investigate and prosecute violations of any of the above to the fullest extent of the law. Carefolio may involve and cooperate with law enforcement authorities in prosecuting users who violate these Terms.

You acknowledge that Carefolio has no obligation to monitor your access to or use of the Site or Carefolio Services or to review or edit any Profile Content, but has the right to do so for the purpose of operating the Site and Carefolio Services, to ensure your compliance with these Terms, and to comply with applicable law or the order or requirement of a court, administrative agency or other governmental body.

Carefolio reserves the right, at any time and without prior notice, to remove or disable access to any Profile Content that Carefolio, at its sole discretion, considers to be in violation of these Terms or otherwise harmful to the Site or Carefolio Services.

# LINKS

The Site may contain links to third-party websites or resources. You acknowledge and agree that Carefolio is not responsible or liable for: (i) the availability or accuracy of such websites or resources; or (ii) the content, products, or services on or available from such websites or resources. Links to such websites or resources do not imply any endorsement by Carefolio of such websites or resources or the content, products, or services available from such websites or resources. You acknowledge sole responsibility for and assume all risk arising from your use of any such websites or resources or the content, products, or services on or available from such websites or resources.

# NOTIFICATION OF COPYRIGHT INFRINGEMENT

We respect the intellectual property rights of others and expects its users to do the same.It is Carefolio’s policy, in appropriate circumstances and at its discretion, to disable and/or terminate the accounts of users who repeatedly infringe or are repeatedly charged with infringing the copyrights or other intellectual property rights of others.

In accordance with the Digital Millennium Copyright Act of 1998, the text of which may be found on the U.S. Copyright Office website, Carefolio will respond expeditiously to claims of copyright infringement committed using the Carefolio website (the “Site”) that are reported to Carefolio’s Designated Copyright Agent, identified in the sample notice below.

If you are a copyright owner, or are authorized to act on behalf of one, or authorized to act under any exclusive right under copyright, please report alleged copyright infringements taking place on or through the Site by completing the following DMCANotice of Alleged Infringement and delivering it to Carefolio’s Designated Copyright Agent. Upon receipt of the Notice as described below, Carefolio will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged material from the Site.

1. Identify the copyrighted work that you claim has been infringed, or – if multiple copyrighted works are covered by this Notice – you may provide a representative list of the copyrighted works that you claim have been infringed.
2. Identify the material that you claim is infringing (or to be the subject of infringing activity) and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material, including at a minimum, if applicable, the URL of the link shown on the Site where such material may be found.
3. Provide your mailing address, telephone number, and, if available, email address.
4. Include both of the following statements in the body of the Notice:
  - “I hereby state that I have a good faith belief that the disputed use of the copyrighted material is not authorized by the copyright owner, its agent, or the law (e.g., as a fair use).”
  - “I hereby state that the information in this Notice is accurate and, under penalty of perjury, that I am the owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed.”
5. Provide your full legal name and your electronic or physical signature.

Deliver this Notice, with all items completed, to Carefolio’s Designated Copyright Agent at copyright@carefol.io.

# TERMINATION AND ACCOUNT CANCELLATION

If you breach any of these Terms, Carefolio will have the right to suspend or disable your Account or terminate these Terms and disable access to your Profiles, including those Profiles to which you have been permitted access by other Members, at its sole discretion and without prior notice to you. Carefolio reserves the right to revoke your access to and use of the Site and Carefolio Services at any time, with or without cause. You may cancel your Account at any time by sending an email to <support@carefol.io> .

# WARRANTIES

You warrant and represent to Carefolio that your use of the Carefolio Services, whether as a Profile-creator or as a Caregiver, is solely for lawful purposes to facilitate the care of the individual that is the subject of the Profile. Neither the Carefolio Services, nor the Site, nor any Carefolio Content, nor any Profile Content may be used in any instance for any unlawful purpose.

# DISCLAIMERS

THE SITE, Carefolio SERVICES, AND Carefolio CONTENT ARE PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED. WITHOUT LIMITING THE FOREGOING, Carefolio EXPLICITLY DISCLAIMS ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT OR NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. Carefolio MAKES NO WARRANTY THAT THE SITE, Carefolio SERVICES OR Carefolio CONTENT WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. Carefolio MAKES NO WARRANTY REGARDING THE QUALITY OF ANY SERVICES OR Carefolio CONTENT AVAILABLE FROM OR OBTAINED THROUGH THE Carefolio SERVICES OR THE ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS, OR RELIABILITY OF ANY Carefolio CONTENT OR PROFILE CONTENT OBTAINED THROUGH THE Carefolio SERVICES. PROFILE CONTENT IS SUBMITTED SOLELY BY MEMBERS AND Carefolio HAS NO RESPONSIBILITY OR LIABILITY FOR THE TRUTHFULNESS OR ACCURACY OF THE SAME.

NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM Carefolio OR THROUGH THE SITE OR Carefolio SERVICES, WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.

# INDEMNITY

YOU AGREE TO DEFEND, INDEMNIFY, AND HOLD HARMLESS Carefolio, ITS OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS, FROM AND AGAINST ANY CLAIMS, LIABILITIES, DAMAGES, LOSSES, AND EXPENSES, INCLUDING, WITHOUT LIMITATION, REASONABLE LEGAL AND ACCOUNTING FEES, ARISING OUT OF OR IN ANY WAY CONNECTED WITH (I) YOUR ACCESS TO OR USE OF THE SITE, Carefolio SERVICES, ANY Carefolio CONTENT OR ANY PROFILE CONTENT (WHETHER OR NOT IT IS A PROFILE YOU CREATED OR OTHERWISE), (II) ANY USE OF OUR SITE OR Carefolio SERVICES FOR ANY UNLAWFUL OR INAPPROPRIATE CONDUCT, OR (III) ANY ACT OR OMISSION BY YOU THAT CONSTITUTES AN ACTUAL OR ALLEGED VIOLATION OF THESE TERMS.

# LIMITATION OF LIABILITY

YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE SITE AND Carefolio SERVICE REMAINS WITH YOU. NEITHER Carefolio NOR ANY OTHER PERSON OR ENTITY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SITE OR Carefolio SERVICES WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR LOSS OF GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE PRODUCTS OR SERVICES, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR EMOTIONAL DISTRESS ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SITE OR Carefolio SERVICES OR THE INABILITY TO USE OR ACCESS A PROFILE OR ANY SPECIFIC PROFILE CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT Carefolio HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.

IN NO EVENT WILL Carefolio’S AGGREGATE LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SITE OR Carefolio SERVICES EXCEED THE AMOUNTS YOU HAVE PAID TO Carefolio FOR USE OF THE SAME, IF YOU HAVE MADE ANY PAYMENTS TO Carefolio FOR USE OF THE SAME OR ONE HUNDRED DOLLARS ($100), WHICHEVER IS GREATER. THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN Carefolio AND YOU. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.

# CONTROLLING LAW AND JURISDICTION

These Terms and any action related thereto will be governed by the laws of the State of Georgia without regard to its conflict of laws provisions. The exclusive jurisdiction and venue of any action to prevent the actual or threatened infringement, misappropriation or violation of a party’s copyrights, trademarks, trade secrets, patents or other intellectual property rights, as contemplated below in “Dispute Resolution” will be the state and federal courts located in the State of Georgia and each of the parties hereto waives any objection to jurisdiction and venue in such courts.

# ENTIRE AGREEMENT

These Terms constitute the entire and exclusive understanding and agreement between Carefolio and you regarding the Site and Carefolio Services, and these Terms supersede and replace any and all prior oral or written understandings or agreements between Carefolio and you regarding the Site and Carefolio Services. In the event that you have elected to access restricted portions of the Site or use additional Carefolio Services, which are subject to additional terms and conditions (including without limitation the Pharmacy Assistance), any additional terms and conditions applicable to that portion of the Site or additional Carefolio Service are expressly included within these Terms as well.

# ASSIGNMENT

You may not assign or transfer these Terms, by operation of law or otherwise, without Carefolio’s prior written consent. Any attempt by you to assign or transfer these Terms, without such consent, will be null and of no effect. Carefolio may assign or transfer these Terms, at its sole discretion, without restriction. Subject to the foregoing, these Terms will bind and inure to the benefit of the parties, their successors and permitted assigns.

# NOTICES

Any notices or other communications permitted or required hereunder, including those regarding modifications to these Terms, will be in writing and given by Carefolio: (i) via email (in each case to the address that you provide); or (ii) by posting to the Site. For notices made by e-mail, the date of receipt will be deemed the date on which such notice is transmitted.

# DISPUTE RESOLUTION

You and Carefolio agree that any dispute, claim or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation or validity thereof or the use of the Carefolio Services (collectively, “Disputes”) will be settled by binding arbitration, except that each party retains the right to bring an individual action in small claims court and the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation or violation of a party’s copyrights, trademarks, trade secrets, patents or other intellectual property rights. You acknowledge and agree that you and Carefolio are each waiving the right to a trial by jury or to participate as a plaintiff or class member in any purported class action or representative proceeding. Further, unless both you and Carefolio otherwise agree in writing, the arbitrator may not consolidate more than one person’s claims, and may not otherwise preside over any form of any class or representative proceeding. If this specific paragraph is held unenforceable, then the entirety of this “Dispute Resolution” section will be deemed void. Except as provided in the preceding sentence, this “Dispute Resolution” section will survive any termination of these Terms.

Arbitration Rules and Governing Law. The arbitration will be administered by the American Arbitration Association (“AAA”) in accordance with the Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes (the “AAA Rules”) then in effect, except as modified by this “Dispute Resolution” section. (The AAA Rules are available from their web site or by calling the AAA at 1-800-778-7879.) The Federal Arbitration Act will govern the interpretation and enforcement of this Section.

Arbitration Process. A party who desires to initiate arbitration must provide the other party with a written Demand for Arbitration as specified in the AAA Rules. (The AAA provides a form Demand for Arbitration and a separate form for California residents.) The arbitrator will be either a retired judge or an attorney licensed to practice law in the state of California and will be selected by the parties from the AAA’s roster of consumer dispute arbitrators. If the parties are unable to agree upon an arbitrator within seven (7) days of delivery of the Demand for Arbitration, then the AAA will appoint the arbitrator in accordance with the AAA Rules.

Arbitration Location and Procedure. Unless you and Carefolio otherwise agree, the arbitration will be conducted in the county where you reside. If your claim does not exceed $10,000, then the arbitration will be conducted solely on the basis of documents you and Carefolio submit to the arbitrator, unless you request a hearing or the arbitrator determines that a hearing is necessary. If your claim exceeds $10,000, your right to a hearing will be determined by the AAA Rules. Subject to the AAA Rules, the arbitrator will have the discretion to direct a reasonable exchange of information by the parties, consistent with the expedited nature of the arbitration.

Arbitrator’s Decision. The arbitrator will render an award within the time frame specified in the AAA Rules. The arbitrator’s decision will include the essential findings and conclusions upon which the arbitrator based the award. Judgment on the arbitration award may be entered in any court having jurisdiction thereof. The arbitrator’s award damages must be consistent with the terms of the “Limitation of Liability” section above as to the types and the amounts of damages for which a party may be held liable. The arbitrator may award declaratory or injunctive relief only in favor of the claimant and only to the extent necessary to provide relief warranted by the claimant’s individual claim. If you prevail in arbitration you will be entitled to an award of attorneys’ fees and expenses, to the extent provided under applicable law. Carefolio will not seek, and hereby waives all rights it may have under applicable law to recover, attorneys’ fees and expenses if it prevails in arbitration.

Fees. Your responsibility to pay any AAA filing, administrative and arbitrator fees will be solely as set forth in the AAA Rules. However, if your claim for damages does not exceed $75,000, Carefolio will pay all such fees unless the arbitrator finds that either the substance of your claim or the relief sought in your Demand for Arbitration was frivolous or was brought for an improper purpose (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)).

Changes. Notwithstanding the provisions of the “Modification” section above, if Carefolio changes this “Dispute Resolution” section after the date you first accepted these Terms (or accepted any subsequent changes to these Terms), you may reject any such change by sending us written notice (including by email to <support@carefol.io>) within 30 days of the date such change became effective, as indicated in the “Last Updated Date” above or in the date of Carefolio’s email to you notifying you of such change. By rejecting any change, you are agreeing that you will arbitrate any Dispute between you and Carefolio in accordance with the provisions of this “Dispute Resolution” section as of the date you first accepted these Terms (or accepted any subsequent changes to these Terms).

# GENERAL

The failure of Carefolio to enforce any right or provision of these Terms will not constitute a waiver of future enforcement of that right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of Carefolio. Except as expressly set forth in these Terms, the exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise. If for any reason a court of competent jurisdiction finds any provision of these Terms invalid or unenforceable, that provision will be enforced to the maximum extent permissible and the other provisions of these Terms will remain in full force and effect.

# CONTACTING Carefolio

If you have any questions about these Terms, please contact Carefolio at <support@carefol.io>

`;
export default tos;
