import React from 'react';
import MaterialTable from 'material-table';
import UriTemplate from 'uritemplate';

import {useApi} from '@arborian/narrf';

const conditionStatusLookup = {
    'ACTIVE': 'Active',
    'RESOLVED': 'Resolved',
}

const columns = [
    {field: 'data.attributes.pcc.rankDescription', title: 'Rank'},
    {field: 'data.attributes.pcc.clinicalStatus', title: 'Status', lookup: conditionStatusLookup},
    {field: 'data.attributes.pcc.classificationDescription', title: 'Classification'},
    {field: 'data.attributes.pcc.icd10Description', title: 'Description'},
    {field: 'data.attributes.pcc.onsetDate', title: 'Onset', type: 'date'},
    {field: 'data.attributes.pcc.resolvedDate', title: 'Resolved', type: 'date'},
]

export default function MedicationTable({patient, ...props}) {
    const api = useApi();
    if(!patient) return null;
    const fetch = async o => {
        const url = UriTemplate.parse(
            api.directory.data.links['artifact.artifacts']
        ).expand({patientId: patient.id, atype: 'condition'});
        let resp = await api.fetchMaterialTable(url, o);
        return resp;
    }
    return (
        <MaterialTable 
            title="Health Conditions"
            data={fetch}
            columns={columns}
            options={{
                filtering: true
            }}
            {...props}
        />
    );
}
