import React, {useEffect, useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';
import UriTemplate from 'uritemplate';
import {useParams, useHistory} from 'react-router-dom';

import {Button, makeStyles} from '@material-ui/core';

import BasePage from '_app/components/BasePage';
import Logo from '_app/components/Logo';
import Footer from '_app/components/Footer';

import {useApi, ducks} from '@arborian/narrf';

import {url_for} from '_app/routes'

import PasswordLoginForm from '_app/components/PasswordLoginForm';
import RegistrationForm from '_app/components/RegistrationForm';


const useStyles = makeStyles(theme => ({
    logoContainer: {
        margin: theme.spacing(2),
    },
    content: {
        flex: 1,
        margin: theme.spacing(2),
    },
    /*
    container: {
        position: 'relative',
    },
    */
    loginContainer: {
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(4),
        },
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-end',
        justifyContent: 'space-evenly',
        width: "100%",
    },
    banner: {
        zIndex: -1
    },
}));


function AcceptInvitationButton({invitation}) {
    const api = useApi();
    const history = useHistory();
    const onClick = async () => {
        await api.fetch(invitation.links.accept, {method: 'POST'});
        history.push(url_for('patientHome', {patientId: invitation.attributes.patient_id}))
    }

    return (
        <Button variant="contained" color="primary" onClick={onClick}>
            Accept invitation
        </Button>
    );
}

export default function InvitationPage() {
    const api = useApi();
    const classes = useStyles();
    const [init, setInit] = useState(false);
    const {invitationId} = useParams();
    const invitation = useSelector(ducks.jsonapi.selectObject(['Invitation', invitationId]));
    console.log('Invitation', invitation, api.isAuthorized());

    const fetchInvitation = useCallback(async () => {
        const url = UriTemplate.parse(
            api.directory.data.links['auth.invitation']
        ).expand({invitationId});
        await api.fetchJsonApi(url);
    }, [api, invitationId]);

    useEffect(() => {
        if(!init) {
            setInit(true);
            fetchInvitation();
        }
    }, [fetchInvitation, init, setInit]);

    return (
        <BasePage>
            <div className={classes.logoContainer}><Logo /></div>
                <div className={classes.content}>
                {!invitation 
                    ? (<h4>Invalid Invitation Link</h4>)
                    : (<>
                        <p>
                            You have been invited to view health information for {fp.get('attributes.patient_name', invitation)}.
                        </p>
                        {(api.isAuthorized() 
                            ? <AcceptInvitationButton invitation={invitation}/>
                            : (fp.isEmpty(fp.get('attributes.sub_ids', invitation)) 
                                ? <RegistrationForm title="Please choose a password to create an account" invitation={invitation}/>
                                : <PasswordLoginForm title="Sign in to accept invitation" invitation={invitation}/>
                            )
                        )}
                    </>)
                }
                </div>
            <Footer wide/>
        </BasePage>
    )
}
