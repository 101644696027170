import React, {useCallback, useState, useEffect} from 'react';
import fp from 'lodash/fp';
import {Icon, IconButton, Tooltip, makeStyles} from '@material-ui/core';
import {Settings} from '@material-ui/icons';

import {useTableManager} from './Provider';

const useStyles = makeStyles(theme => ({
    hidden: {
        display: 'none',
    },
}));

export default function DTableAction({
    children,
    icon,
    onClick,
    tooltip,
    iconProps,
    free = false,
    disabled = false,
    hidden = false,
}) {
    const tm = useTableManager();
    const classes = useStyles();
    const [id] = useState(() => Math.random().toString());

    let overrideFunction = null;
    if (children) {
        if (children.length === 1 && fp.isFunction(children[0])) {
            overrideFunction = children[0];
        } else {
            console.error(
                'Children must be a single function returning overrides',
                {children},
            );
        }
    }

    const render = useCallback(
        data => {
            let renderProps = {
                icon,
                onClick,
                tooltip,
                disabled,
                hidden,
                iconProps,
            };
            if (overrideFunction) {
                renderProps = fp.merge(
                    renderProps,
                    overrideFunction(data, renderProps),
                );
            }
            if (!renderProps.icon) {
                renderProps.icon = <Settings {...renderProps.iconProps} />;
            }
            let result = (
                <IconButton
                    disabled={renderProps.disabled}
                    className={renderProps.hidden ? classes.hidden : null}
                    onClick={ev => renderProps.onClick(ev, data)}
                >
                    {fp.isString(renderProps.icon) ? (
                        <Icon {...renderProps.iconProps}>{icon}</Icon>
                    ) : fp.isFunction(renderProps.icon) ? (
                        renderProps.icon(data)
                    ) : (
                        renderProps.icon
                    )}
                </IconButton>
            );
            if (renderProps.tooltip) {
                result = (
                    <Tooltip title={renderProps.tooltip}>{result}</Tooltip>
                );
            }
            return result;
        },
        [
            overrideFunction,
            classes.hidden,
            icon,
            onClick,
            tooltip,
            disabled,
            hidden,
            iconProps,
        ],
    );

    useEffect(() => {
        tm.setAction(id, {id, free, render});
    }, [id, free, render]); // eslint-disable-line react-hooks/exhaustive-deps
    return null;
}
