import React from 'react';
import * as R from 'react-router-dom';
import clsx from 'clsx';

import {Link, makeStyles} from '@material-ui/core';

import {url_for} from '_app/routes';

const useStyles = makeStyles(theme => ({
    footer: {
        backgroundColor: 'rgb(250,250,250)',
        borderTop: '1px solid #ccc',
        textAlign: 'center',
        '& p': {
            margin: 0
        },
    },
    responsiveFooter: {
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.constants.drawerWidth
        },
    },
    footerRow: {
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    link: {
        margin: 10,
    },
    copy: {
        textAlign: 'center',
    }
}));

export default function Footer({wide=false}) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.footer, !wide && classes.responsiveFooter)}>
            <div className={classes.footerRow}>
                <Link component={R.Link} to={url_for('terms')} className={classes.link}>Terms of Service</Link>
                <Link component={R.Link} to={url_for('privacy')} className={classes.link}>Privacy Policy</Link>
                <Link href="mailto:support@carefol.io" className={classes.link}>Email Support at support@carefol.io</Link>
            </div>
            <p>
                Copyright 2020-2021 Carefol.io, Inc. All rights reserved.
            </p>
            {/*
                <br/>

                Icons made by <a href="https://www.flaticon.com/authors/smashicons" 
                    title="Smashicons">Smashicons</a> and <a 
                    href="https://www.flaticon.com/authors/freepik" 
                    title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" 
                    title="Flaticon">www.flaticon.com</a>
            </p>
            */}
        </div>
    )
}
