import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {createSelector} from '@reduxjs/toolkit';
import fp from 'lodash/fp';

import {makeStyles, Hidden} from '@material-ui/core';

import BasePage from '_app/components/BasePage';
import Logo from '_app/components/Logo';
import Banner from '_app/components/Banner';
import Footer from '_app/components/Footer';

// import PatientSearch from '_app/components/PatientSearch';
import PatientCard from '_app/components/PatientCard';

import {ducks, useApi} from '@arborian/narrf';


const useStyles = makeStyles(theme => ({
    logoContainer: {
        margin: theme.spacing(2),
    },
    header: {
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(4),
    },
    content: {
        position: 'relative',
        backgroundColor: '#F5F6FA',
        padding: theme.spacing(2),
        flex: 1,
    },
    container: {
        padding: theme.spacing(4),
    },
    searchBar: {
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            bottom: theme.spacing(4),
            left: theme.spacing(4),
            right: theme.spacing(4),
        }
    },
    bannerArea: {
        position: 'relative',
        height: theme.spacing(36),
    },
    patientCards: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
    },
    textField: {
        marginTop: theme.spacing(2),
    },
}));


function SearchBanner() {
    const classes = useStyles();
    const userinfo = useSelector(ducks.auth.selectUserinfo)
    if(!userinfo) return null;
    return (
        <div>
            <Hidden xsDown>
                <div className={classes.bannerArea}>
                    <Banner>
                        <h6>Welcome back, {userinfo.name}!</h6>
                        <p>
                            Please select the card for the patient you wish to view.
                        </p>
                        <p>
                            If you do not see a card you expect, please contact the 
                            administrator at their location.
                        </p>
                    </Banner>
                    {/*
                    <div className={classes.searchBar}><PatientSearch /></div>
                    */}
                </div>
            </Hidden>
            {/*
            <Hidden smUp>
                <div className={classes.searchBar}><PatientSearch /></div>
            </Hidden>
            */}
        </div>
    );
}

const selectMyPatients = createSelector(
    ducks.jsonapi.selectObject('Patient'), fp.pipe([
        fp.values,
        fp.filter(p => fp.get('attributes.sync_status.pcc', p) === 'linked'),
        fp.sortBy('attributes.pcc.lastName')
    ])
)

export default function PatientSelectPage() {
    const api = useApi();
    const classes = useStyles();
    const [init, setInit] = useState(false);
    useEffect(() => {
        if(!init) {
            setInit(true);
            api.fetchAllJsonApi(api.directory.data.links['patient.patients'], {
                filter: {'sync_status.pcc': 'linked'},
                include: ['photo']
            });
        }
    }, [api, init, setInit]);

    const patients = useSelector(selectMyPatients);

    return (
        <BasePage>
            <div className={classes.logoContainer}><Logo /></div>
            <div className={classes.content}>
                <SearchBanner />
                <div className={classes.patientCards}>
                    {fp.map(pt => <PatientCard key={pt.id} patient={pt}/>, patients)}
                </div>
            </div>
            <Footer wide />
        </BasePage>
    )
}
