import React from 'react';
import * as yup from 'yup';
import {useSnackbar} from 'notistack';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';

import {
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, 
    Button,
} from '@material-ui/core';

import {useApi} from '@arborian/narrf';

import {url_for} from '_app/routes';

const PasswordResetSchema = yup.object().shape({
    email: yup.string().email().lowercase().required(),
});

export default function ForgotPasswordDialog({open, onClose}) {
    const api = useApi();
    const initialValues = {email: ''};
    const {enqueueSnackbar} = useSnackbar();
    const onSubmit = async (values, formikProps) => {
        try {
            const url = new URL(url_for('passwordReset'), window.location);
            await api.fetch(api.directory.data.links['auth.passwordResetRequest'], {
                method: 'POST',
                json: {data: {type: 'PasswordResetRequest', attributes: {
                    email: values.email,
                    url
                }}}
            });
            formikProps.resetForm();
            onClose();
            enqueueSnackbar('Password reset requested. Check your email for a link to reset your password.', {
                persist: true,
                variant: 'success',
            });
        } catch(e) {
            console.error('Error resetting password')
            formikProps.setFieldError('email', 'Email not found')
        }
    }
    return (
        <Dialog open={open} onClose={onClose}>
            <Formik 
                initialValues={initialValues}
                validationSchema={PasswordResetSchema}
                enableReinitialize
                onSubmit={onSubmit}
            >{formikProps => (<>
                <Form onSubmit={formikProps.handleSubmit}>
                    <DialogTitle>Reset password</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter your email address below.
                            If that email has an account with us, you should
                            receive a message with instructions on how to 
                            reset your password.
                        </DialogContentText>
                        <Field fullWidth variant='outlined' component={TextField} 
                            name='email' label='Your email' />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button variant="contained" color="primary" type="submit">Request password reset</Button>
                    </DialogActions>
                </Form>
            </>)}</Formik>
        </Dialog>
    );

}
