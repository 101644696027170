import React, {useState, useEffect} from 'react';
import fp from 'lodash/fp';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    MenuItem,
    TextField,
    Button,
} from '@material-ui/core';
import {Mail} from '@material-ui/icons';

import {useApi, ducks, components} from '@arborian/narrf';
import {humanName} from '_app/util';
import {MESSAGE_TYPES} from '_app/constants';

const defaultMessage = {
    type: 'message',
    subject: '',
    text: '',
};

export default function MessageCompositionDialog({patient, onClose}) {
    const api = useApi();
    const [message, setMessage] = useState(defaultMessage);

    useEffect(() => {
        setMessage(defaultMessage);
    }, [patient]);

    const setField = name => ev =>
        setMessage(fp.set(name, ev.target.value, message));

    const handleSend = async () => {
        let resp = await api.fetchJson(
            api.directory.data.links['message.MessageCollection'],
            {
                method: 'POST',
                json: {
                    data: {
                        type: 'Message',
                        attributes: {
                            patient_id: patient.id,
                            ...message,
                        },
                    },
                },
            },
        );
        console.log('Message is', resp);
        onClose(resp);
    };

    return (
        <Dialog open={!!patient} onClose={onClose}>
            <DialogTitle>
                Compose message to{' '}
                {patient && humanName(patient.attributes.pcc)}
            </DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    select
                    label='Message type'
                    onChange={setField('type')}
                >
                    {fp.map(
                        o => (
                            <MenuItem key={o.value} value={o.value}>
                                {o.label}
                            </MenuItem>
                        ),
                        MESSAGE_TYPES,
                    )}
                </TextField>
                <TextField
                    fullWidth
                    label='Subject'
                    onChange={setField('subject')}
                />
                <TextField
                    multiline
                    fullWidth
                    rows={6}
                    label='Message text'
                    onChange={setField('text')}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    onClick={handleSend}
                    startIcon={<Mail />}
                    variant='contained'
                    color='primary'
                >
                    Send message
                </Button>
            </DialogActions>
        </Dialog>
    );
}
