import {useLocation} from 'react-router-dom';
import fp from 'lodash/fp';
import {reverse} from 'named-urls';
import {createBrowserHistory} from 'history';

export const history = createBrowserHistory();

const routeList = [
    {name: 'home', path: '/'},
    {name: 'login', path: '/login'},
    {name: 'logout', path: '/logout'},
    {name: 'callback', path: '/callback'},
    {name: 'register', path: '/register'},
    {name: 'passwordReset', path: '/password-reset/:tok'},
    {name: 'invitation', path: '/invitation/:invitationId'},
    {name: 'profile', path: '/profile'},
    {name: 'about', path: '/about'},
    {name: 'terms', path: '/terms'},
    {name: 'privacy', path: '/privacy'},

    {name: 'adminPatients', path: '/adminPatients'},
    {name: 'adminOrg', path: '/adminOrg'},
    {name: 'adminUsers', path: '/adminUsers'},
    {name: 'adminMessage', path: '/adminMessage'},

    {name: 'patientSelect', path: '/patient-select'},
    {name: 'patientHome', path: '/patient/:patientId'},
    {name: 'history', path: '/patient/:patientId/history/:type'},
    {name: 'info', path: '/patient/:patientId/info/:type'},
];

export const routes = fp.transform(
    (acc, r) => {
        acc[r.name] = r;
    },
    {},
    routeList,
);

export const useQuery = () => new URLSearchParams(useLocation().search);

export const url_for = (route, params) => {
    try {
        let {path} = routes[route];
        return reverse(path, params);
    } catch (e) {
        console.error('Error looking up route', route, params);
        throw e;
    }
};

export default routes;
