import React from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';
import * as R from 'react-router-dom';

import {
    Toolbar,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Hidden,
    makeStyles,
} from '@material-ui/core';
import * as I from '@material-ui/icons';

import {ducks} from '@arborian/narrf';

import {url_for} from '_app/routes';

const useStyles = makeStyles(theme => ({
    drawer: {
        width: theme.constants.drawerWidth,
    },
    logo: {
        height: 48,
    },
}));

function NavMenu() {
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    const scopes = fp.get('scopes', userinfo);
    const isSuperuser = fp.includes('__admin__', scopes);
    const isOrgAdmin = fp.includes('orgadmin', scopes);
    const isFacAdmin = fp.includes('facadmin', scopes);
    const isViewer = fp.includes('viewer', scopes);
    return (
        <List>
            <ListItem button component={R.Link} to={url_for('home')}>
                <ListItemIcon>
                    <I.Home />
                </ListItemIcon>
                <ListItemText primary='Home' />
            </ListItem>
            {isSuperuser && (
                <ListItem button component={R.Link} to={url_for('adminUsers')}>
                    <ListItemIcon>
                        <I.Security />
                    </ListItemIcon>
                    <ListItemText primary='System Administration' />
                </ListItem>
            )}
            {isOrgAdmin && (
                <ListItem button component={R.Link} to={url_for('adminOrg')}>
                    <ListItemIcon>
                        <I.Business />
                    </ListItemIcon>
                    <ListItemText primary='Admin Org' />
                </ListItem>
            )}
            {isFacAdmin && (
                <ListItem
                    button
                    component={R.Link}
                    to={url_for('adminPatients')}
                >
                    <ListItemIcon>
                        <I.SupervisorAccount />
                    </ListItemIcon>
                    <ListItemText primary='Admin Patients' />
                </ListItem>
            )}
            {isFacAdmin && (
                <ListItem
                    button
                    component={R.Link}
                    to={url_for('adminMessage')}
                >
                    <ListItemIcon>
                        <I.Drafts />
                    </ListItemIcon>
                    <ListItemText primary='Message patients' />
                </ListItem>
            )}
            {isViewer && !isFacAdmin && (
                <ListItem
                    button
                    component={R.Link}
                    to={url_for('patientSelect')}
                >
                    <ListItemIcon>
                        <I.CompareArrows />
                    </ListItemIcon>
                    <ListItemText primary='Switch Patient' />
                </ListItem>
            )}
        </List>
    );
}

export default function NavDrawer({open, onClose}) {
    const classes = useStyles();
    return (
        <>
            <Hidden smDown>
                <Drawer
                    variant='persistent'
                    classes={{paper: classes.drawer}}
                    open
                >
                    <Toolbar>
                        <img
                            alt='logo'
                            src='/img/web-logo.png'
                            className={classes.logo}
                        />
                    </Toolbar>
                    <Divider />
                    <NavMenu />
                </Drawer>
            </Hidden>
            <Hidden mdUp>
                <Drawer
                    classes={{paper: classes.drawer}}
                    open={open}
                    onClose={onClose}
                >
                    <NavMenu />
                </Drawer>
            </Hidden>
        </>
    );
}
