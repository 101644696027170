import React from 'react';
import {
    AppBar as MUIAppBar, Toolbar,
    IconButton,
    makeStyles, 
} from '@material-ui/core';

import CFIcon from '_app/art/CFIcon';

const useStyles = makeStyles(theme => ({
    appBar: {
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.constants.drawerWidth
        },
        width: undefined,
    },
}));


export default function AppBar({
    onClickLogo, children, 
    ...other
}) {
    const classes = useStyles();
    return (
        <MUIAppBar position="static" color="inherit" 
            className={classes.appBar}
            {...other}>
            <Toolbar>
                <IconButton onClick={onClickLogo}>
                    <CFIcon />
                </IconButton>
                {children}
            </Toolbar>
        </MUIAppBar>
    )
}
