import React from 'react';

import InfoCard from '_app/components/InfoCard';
import {HeartRateAvatar} from '_app/art/MedicalIcons';

export default function ConditionsCard(props) {
    return (
        <InfoCard color='blue' Avatar={HeartRateAvatar} title="Conditions" {...props}>
        </InfoCard>
    )
}
