import React, {useRef, useState, useEffect, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {Button, makeStyles} from '@material-ui/core';
import {Mail, MailOutline, Drafts, Markunread} from '@material-ui/icons';

import {useApi, ducks, components} from '@arborian/narrf';

import {MESSAGE_TYPES} from '_app/constants';
import {selectFacilityNames} from '_app/duck';
import MessageDetailPanel from '_app/components/MessageDetailPanel';
import {DateTime} from '_app/components/DateTime';
import PatientAvatar from '_app/components/PatientAvatar';
import MessageCompositionDialog from '_app/components/MessageCompositionDialog';
import {humanName} from '_app/util';

const {
    DataTable,
    Column,
    Action,
    DetailPanel,
    useLocationFetchOptions,
    updateLocationFetchOptions,
} = components;

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(2, 0),
        // minWidth: theme.spacing(32),
        '& .MuiPaper-root': {
            backgroundColor: theme.palette.cf.blue + '0c',
            padding: theme.spacing(2),
        },
        '& thead.MuiTableHead-root th': {
            backgroundColor: theme.palette.cf.blue + '0c',
        },
    },
    unread: {
        '& .MuiTableCell-root': {
            fontWeight: 'bolder',
        },
    },
    hidden: {display: 'none'},
}));

const lookupMessageTypes = fp.pipe([
    fp.map(o => [o.value, o.label]),
    fp.fromPairs,
])(MESSAGE_TYPES);

function MessageDetails({message}) {
    return (
        <div>
            <strong>Type:</strong> {fp.get('attributes.type', message)}
            <br />
            <strong>Sender:</strong> {fp.get('attributes.from_', message)}
            <br />
            <strong>Subject:</strong> {fp.get('attributes.subject', message)}
            <br />
            <strong>Sent:</strong>{' '}
            <DateTime value={fp.get('attributes.timestamp', message)} />
            <br />
            <p>{fp.get('attributes.text', message)}</p>
        </div>
    );
}

function PatientMessageTable({patient, onCompose}) {
    const api = useApi();
    const classes = useStyles();
    const tableRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();

    const fetchData = useCallback(
        fetchOptions => {
            return api.fetchDataTable(
                api.directory.data.links['message.MessageCollection'],
                {
                    filter: {'attributes.patientId': patient.id},
                    ...fetchOptions,
                },
            );
        },
        [api],
    );

    const refresh = () => tableRef.current.fetch();

    const defaultFetchOptions = {
        sort: {field: 'attributes.timestamp', direction: 'desc'},
        page: {size: 5},
    };

    const fetchOptions = useLocationFetchOptions(defaultFetchOptions, 'msg.');
    const onChangeFetchOptions = useCallback(
        o => {
            const newParams = updateLocationFetchOptions(o, 'msg.');
            history.push('?' + newParams.toString());
        },
        [history],
    );

    const onRowDelete = async row => {
        await api.fetchJson(row.data.links.self, {method: 'DELETE'});
        dispatch(ducks.jsonapi.deleteData(row.data));
    };

    return (
        <>
            <DataTable
                title={humanName(fp.get('attributes.pcc', patient))}
                tableRef={tableRef}
                data={fetchData}
                options={{
                    search: true,
                    filtering: true,
                }}
                editable={{onRowDelete}}
                fetchOptions={fetchOptions}
                onChangeFetchOptions={onChangeFetchOptions}
            >
                <Column
                    title='Type'
                    field='attributes.type'
                    lookup={lookupMessageTypes}
                />
                <Column title='Sender' field='attributes.from_' />
                <Column title='Subject' field='attributes.subject' />
                <Column
                    title='Sent'
                    field='attributes.timestamp'
                    render={row => (
                        <DateTime
                            value={fp.get('data.attributes.timestamp', row)}
                        />
                    )}
                />
                <DetailPanel
                    render={row => {
                        return <MessageDetailPanel message={row.data} />;
                    }}
                />
                <Action
                    free
                    onClick={refresh}
                    tooltip='Refresh'
                    icon='refresh'
                />
                <Action
                    free
                    onClick={() => onCompose(patient)}
                    tooltip='Compose'
                    icon='add'
                />
            </DataTable>
        </>
    );
}

export default function AdminMessageTable() {
    const api = useApi();
    const classes = useStyles();
    const [init, setInit] = useState(false);
    const facilityLookup = useSelector(selectFacilityNames);
    const [patient, setPatient] = useState(null);
    const tableRef = useRef();

    useEffect(() => {
        if (!init) {
            setInit(true);
            api.fetchAllJsonApi(api.directory.data.links['pcc.facilities']);
        }
    }, [api, init, setInit]);

    const refresh = () => tableRef.current.fetch();

    const fetchData = fetchOptions => {
        return api.fetchDataTable(
            api.directory.data.links['patient.get_patients'],
            fetchOptions,
            {filter: {'sync_status.pcc': 'linked'}, include: ['photo']},
        );
    };

    return (
        <div className={classes.root}>
            <DataTable
                title='Select patient'
                options={{search: true}}
                data={fetchData}
                tableRef={tableRef}
            >
                <Column render={row => <PatientAvatar patient={row.data} />} />
                <Column title='Last name' field='attributes.pcc.lastName' />
                <Column title='First name' field='attributes.pcc.firstName' />
                <Column
                    title='Birth Date'
                    field='attributes.pcc.birthDate'
                    type='date'
                />
                <Column
                    title='Facility'
                    field='attributes.s_facId'
                    type='numeric'
                    lookup={facilityLookup}
                />
                <DetailPanel
                    icon={<MailOutline />}
                    openIcon={<Mail />}
                    render={row => (
                        <PatientMessageTable
                            patient={row.data}
                            onCompose={() => setPatient(row.data)}
                        />
                    )}
                />
                <Action
                    free
                    onClick={refresh}
                    tooltip='Refresh'
                    icon='refresh'
                />
            </DataTable>
            <MessageCompositionDialog
                patient={patient}
                onClose={() => setPatient(null)}
            />
        </div>
    );
}
