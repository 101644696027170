import React from 'react';
import fp from 'lodash/fp';

import {Avatar, Button, makeStyles} from '@material-ui/core';
import * as I from '@material-ui/icons';

import {useApi} from '@arborian/narrf';

const useStyles = makeStyles(theme => ({
    button: {
        width: 244,
        height: 38,
        marginTop: 6,
        marginBottom: 6,
    },
    avatar: {
        width: 16,
        height: 16,
    },
}));


export default function BaseLoginButton({provider, children, intent, state, ...props}) {
    const api = useApi();
    const classes = useStyles();

    if(!provider) return null;
    const redirect_uri = new URL('/callback', window.location.href);
    const authorizeLink = api.authorizeLink({
        redirect_uri, provider_id: provider.id, intent,
        state
    });
    const image = fp.get('attributes.image_url', provider);
    const avatar = <Avatar classes={{root: classes.avatar}} src={image} icon={<I.Face/>}/>
    return (
        <Button 
            classes={{root: classes.button}} 
            href={authorizeLink} 
            variant="outlined" 
            startIcon={avatar} {...props}
        >
            {children}
        </Button>
    );

}

