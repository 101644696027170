import React, {useRef} from 'react';
import {useSelector} from 'react-redux';
import * as yup from 'yup';
import fp from 'lodash/fp';
import {useDispatch} from 'react-redux';

import MaterialTable from 'material-table';
import {useSnackbar} from 'notistack';

import {useApi, ducks} from '@arborian/narrf';
import {url_for} from '_app/routes';

const emailSchema = yup.string().email().lowercase().required();

const columns = [
    {title: 'Name', field: 'data.attributes.name'},
    {
        title: 'Email', field: 'data.attributes.email', 
        validate: row => {
            const value = fp.get('data.attributes.email', row);
            try {
                emailSchema.validateSync(value);
                return true
            } catch(e) {
                return e.errors[0];
            }
        }
    },
    {
        title: 'Expires', field: 'data.attributes.expires', type: 'date',
        editable: false, 
    },
];

export default function InvitationTable({patient}) {
    const api = useApi();
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    const tableRef = useRef();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    const fetchData = async o => {
        const resp = await api.fetchMaterialTable(
            patient.relationships.invitations.links.related,
            o
        );
        return resp;
    };

    const onRowAdd = async row => {
        const url = new URL(url_for('invitation'), window.location);
        await api.fetchJsonApi(
            patient.relationships.invitations.links.related, 
            {
                method: 'POST',
                json: { data: {
                    type: 'Invitation',
                    attributes: {
                        ...row.data.attributes,
                        email: row.data.attributes.email.toLowerCase(),
                        creator_name: userinfo.name,
                        url
                    }
                }},
            }
        );
    }

    const onRowDelete = async row => {
        await api.fetch(row.data.links.self, {method: 'DELETE'});
        dispatch(ducks.jsonapi.deleteData(row.data));
    }

    const refresh = () => tableRef.current.onQueryChange();

    const resend = async (ev, row, ...args) => {
        await api.fetchJsonApi(row.data.links.resend, {method: 'POST'})
        enqueueSnackbar('Invitation sent.', {
            persist: false,
            variant: 'success',
        });
    }

    return (
        <MaterialTable title="Invitations"
            data={fetchData}
            tableRef={tableRef}
            editable={{onRowAdd, onRowDelete}}
            options={{
                filtering: false,
                search:false,
            }}
            actions={[
                {icon: 'refresh', isFreeAction: true, tooltip: 'Refresh', onClick: refresh},
                {icon: 'mail', tooltip: 'Re-send invitation', onClick: resend},
            ]}
            columns={columns}
        />
    );
}
