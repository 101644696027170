import React from 'react';

import {Tooltip, Button, makeStyles} from '@material-ui/core';
import * as I from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    truncated: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    }
}));

export default function TelephoneButton({value, icon, ...other}) {
    const classes = useStyles();
    const href = `tel:${value}`;
    return (
        <Tooltip title={value}>
            <Button href={href} {...other}>
                {icon || <I.Phone />}
                &nbsp;
                <span className={classes.truncated}>
                    {value}
                </span>
            </Button>
        </Tooltip>
    )
}
