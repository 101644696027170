import React from 'react';
import fp from 'lodash/fp';

export default function tableColumn({field, component, ...rest}) {
    const Component = component;

    return {
        ...rest,
        field, 
        render: rowData => <Component rowData={rowData} field={field} value={fp.get(field, rowData)}/>,
        editComponent: component,
    }
}
