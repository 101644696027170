import React from 'react';
import fp from 'lodash/fp';
import * as yup from 'yup';
import {useHistory} from 'react-router-dom';
import {Formik, Form, Field} from 'formik'

import {Button, makeStyles} from '@material-ui/core';
import {TextField} from 'formik-material-ui';

import {useApi} from '@arborian/narrf';

import {url_for} from '_app/routes';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: theme.spacing(80),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.cf.accent,
        border: '1px solid #D1D3D4',
        borderRadius: 10,
        boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.25)',
    },
    textField: {
        margin: theme.spacing(1, 0),
        '& .MuiInputBase-root': {
            backgroundColor: 'white'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000',
        },
    }
}));

const SignupSchema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().lowercase().required(),
    password: yup.string().min(6).required(),
    password2: yup.string().oneOf([yup.ref('password'), null], "Passwords don't match"),
});


export default function RegistrationForm({invitation, title, next}) {
    const api = useApi();
    const history = useHistory();
    const classes = useStyles();
    const onSubmit = async values => {
        await api.fetchJson(invitation.links.register, {
            method: 'POST',
            json: {data: {
                type: 'Registration',
                attributes: values
            }}
        });
        await api.loginSrp(values.email, values.password);
        if(next) {
            history.push(url_for('patientSelect'))
        }
    }
    const initialValues = {
        name: fp.getOr('', 'attributes.name', invitation),
        email: fp.getOr('', 'attributes.email', invitation),
        password: '',
        password2: '',
    }
    return (
        <div className={classes.root}>
            <Formik 
                initialValues={initialValues} 
                enableReinitialize 
                onSubmit={onSubmit}
                validationSchema={SignupSchema}
            >{formikProps => (
                <Form onSubmit={formikProps.handleSubmit}>
                    <h6>{title}</h6>
                    <Field fullWidth className={classes.textField} variant="outlined" component={TextField}
                        name="name" label="Your name"/>
                    <Field fullWidth className={classes.textField} variant="outlined" component={TextField}
                        name="email" label="Your email" inputProps={{readOnly: true}}/>
                    <Field fullWidth className={classes.textField} variant="outlined" component={TextField}
                        type="password" name="password" label="Password"/>
                    <Field fullWidth className={classes.textField} variant="outlined" component={TextField}
                        type="password" name="password2" label="Password (again)"/>
                    <Button type="submit" fullWidth variant="contained" color="primary">
                        Create account
                    </Button>
                </Form>
            )}</Formik>
        </div>
    )
}
