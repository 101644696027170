import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import UriTemplate from 'uritemplate';

import {Divider, makeStyles} from '@material-ui/core';

import PatientPage from '_app/components/PatientPage';
import PatientInbox from '_app/components/PatientInbox';
import VitalsDashboard from '_app/components/VitalsDashboard';
import InfoDashboard from '_app/components/InfoDashboard';

import {ducks, useApi} from '@arborian/narrf';

const useStyles = makeStyles(theme => ({
    section: {
        '& header': {
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        '& header hr': {
            margin: theme.spacing(2),
            flex: 1,
        },
    },
}));

export default function PatientHomePage() {
    const api = useApi();
    const classes = useStyles();
    const {patientId} = useParams();
    const [init, setInit] = useState(false);
    useEffect(() => {
        if (!init) {
            setInit(true);
            let url = UriTemplate.parse(
                api.directory.data.links['patient.patient'],
            ).expand({patientId});
            api.fetchJsonApi(url, {
                include: ['facility', 'photo', 'latest_observations'],
            });
        }
    }, [api, patientId, init, setInit]);

    const patient = useSelector(
        ducks.jsonapi.selectObject(['Patient', patientId]),
    );

    return (
        <PatientPage title='Home' patient={patient}>
            <section className={classes.section}>
                <PatientInbox patient={patient} />
            </section>
            <section className={classes.section}>
                <header>
                    <h5>Health stats</h5>
                    <Divider />
                    {/* IDK what the 'detailed stats' might be here....
                    <Button variant="contained" color="primary">See detailed stats</Button>
                    */}
                </header>
                <VitalsDashboard patient={patient} />
            </section>
            <section className={classes.section}>
                <header>
                    <h5>Health info</h5>
                    <Divider />
                    {/* IDK what the 'detailed info' might be here....
                    <Button variant="contained" color="primary">See detailed info</Button>
                    */}
                </header>
                <InfoDashboard patient={patient} />
            </section>
        </PatientPage>
    );
}
